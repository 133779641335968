<template>
  <el-dialog append-to-body
             :visible.sync="show"
             :show-close="false"
             :close-on-click-modal="false"
             :close-on-press-escape="false"
             @close="$emit('invoice-close')"
             :width="dialogWidth">
    <!-- 待开发票详情 -->
    <div class="dialog-content" v-if="stage =='toBilling'">
      <div class="dialog-title">
        <span class="title">请核对发票信息</span>
        <span
            v-if="isShowInfo">{{ handleValueToLabel('BillingType', issue.billingType) }}剩余&nbsp;<b>{{
            issue.total
          }}</b>&nbsp;张</span>
      </div>
      <div class="dialog-main">

        <div class="row-first">
          <label>开具标识：</label>
          <span>{{ handleBillingFlag() }}</span>
        </div>
        <div class="row-first">
          <label>发票类型：</label>
          <span>{{ handleValueToLabel('BillingType', issue.billingType) }}</span>
        </div>
        <!-- <div class="row-first" v-if="isShowRedNoticeNo && failRedInvoice">
          <label><span style="color:red;">*&nbsp;</span>开票红字通知单号：</label>
          <el-input v-model="issue.redNoticeNo" maxlength="30"/>
        </div> -->
        <div  v-if="issue.billingType == 'QDZP' || issue.billingType == 'QDPP'" >
          <div class="row-first"  v-if="isRedInvoice && failRedInvoice">
            <label>
              <span>&nbsp;</span>对应正数发票代码：</label>
            <el-input v-model="issue.originInvoiceCode" maxlength="12"/>
          </div>
        </div>
        <div  v-else >
          <div class="row-first"  v-if="isRedInvoice && !eInv  && failRedInvoice">
            <label>
              <span  style="color:red;">*&nbsp;</span>
            对应正数发票代码：</label>
            <el-input v-model="issue.originInvoiceCode" maxlength="12"/>
          </div>
        </div>
        <div class="row-first" v-if="isRedInvoice && failRedInvoice">
          <label><span style="color:red;">*&nbsp;</span>对应正数发票号码：</label>
          <el-input v-model="issue.originInvoiceNo" />
        </div>
        <div class="row-first" v-if="isRedInvoice && isDigitalInvoice && failRedInvoice">
          <label><span style="color:red;">*&nbsp;</span>自动申请红字通知单：</label>
          <el-select @change="handlerSelect" v-model="issue.auToRedNotice" placeholder="请选择">
            <el-option v-for="item in isFastToredList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
           <span v-if="!issue.auToRedNotice" @click="handlerInquire" class="inquire_style">查询</span>
        </div>
        <div class="row-first" v-if="isShowRedNoticeNo && isRedInvoice && !issue.auToRedNotice && failRedInvoice ">
          <label><span style="color:red;">*&nbsp;</span>红字通知单号：</label>
          <el-input v-model="issue.redNoticeNo" />
        </div>
        <div class="row-first" v-if="isRedInvoice && !issue.auToRedNotice  && isDigitalInvoice && failRedInvoice">
          <label><span style="color:red;">*&nbsp;</span>红字通知单UUID：</label>
          <el-input v-model="issue.redNoticeUuid" />
        </div>
        <div class="row-first" v-if="isRedInvoice && failRedInvoice">
          <label><span style="color:red;">*&nbsp;</span>冲红原因：</label>
          <el-select  v-model="issue.toRedRemark" placeholder="请选择">
            <el-option v-for="item in flushedCauseList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </div>
        <div v-if="ifShowNoCode && !eInv" v-loading="inventoryLoading">
          <div class="row-first">
            <label>发票代码：</label>
            <span>{{ issue.invoiceCode }}</span>
          </div>
          <div class="row-first">
            <label>发票号码：</label>
            <span>{{ issue.invoiceNo }}</span>
          </div>
        </div>
        <div class="row-first">
          <label>开票金额：</label>
          <span>{{ '￥' + Number(issue.sumAmount)}}</span>
        </div>
        <div class="dialog-split-line"></div>
        <div class="row-first">
          <label>客户名称：</label>
          <span>{{ issue.buyerName }}</span>
        </div>
        <div class="row-first">
          <label>客户税号：</label>
          <span>{{ issue.buyerTaxNo }}</span>
        </div>
        <div class="row-first">
          <label>地址电话：</label>
          <span>{{ issue.buyerAddressTel }}</span>
        </div>
        <div class="row-first">
          <label>银行账号：</label>
          <span>{{ issue.buyerBankAccount }}</span>
        </div>
        <div v-if="issue.remark.length" class="row-first">
          <label>备注：</label>
          <el-input class="text_remark"
                    type="textarea"
                    :autosize="{ minRows: 2, maxRows: 4}"
                    placeholder="请输入内容"
                    v-model="issue.remark"
                    maxlength="280"
          />
        </div>
        <div class="dialog-split-line" v-show="issue.billingType !== 'Dzpp' || issue.billingType !== 'DZZP'"></div>
        <span class="dialog-tip error" v-show="(issue.billingType !== 'QDZP' && issue.billingType !== 'QDPP')
                                                && (issue.billingType !== 'Dzpp' || issue.billingType !== 'DZZP')">
          请认真核对装入打印机中的纸质发票种类、代码、号码是否与当前展示的信息一致，如一致，可执行打印操作；如不一致，请予以更换。</span>
        <span class="dialog-tip error" v-show="issue.billingType === 'QDZP' || issue.billingType === 'QDPP'">若需要在电子税务局（线下）进行发票开具，点击“确认开具”后，需通过开票记录模块，“全电开票导出”功能进行导出。</span>
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button :loading="loading" type="primary" @click="handleInvoiceBillingConfirm('N')">确认开具</el-button>
        <el-button :loading="loading" type="default"  @click="handleBillingAndPrint">开具并打印
        </el-button>
      </div>
    </div>
    <!-- 发票开具中 -->
    <div class="dialog-content" v-if="stage == 'isBilling'">
      <div class="dialog-title">提示</div>
      <div class="dialog-main">
        <span class="progress-msg">发票开具中请勿关闭界面！</span>
        <el-progress :text-inside="true" :stroke-width="24" :percentage="percentage" :color="colors"
                     status="success"></el-progress>
      </div>
    </div>
    <!-- 批量开具发票 -->
    <div class="dialog-content" v-if="stage == 'batchBilling'">
      <div class="dialog-title"></div>
      <div class="dialog-main"></div>
      <div class="dialog-footer" slot="footer"></div>
    </div>
  </el-dialog>
</template>

<script>
import {getInventory, updateInvoiceRemark, getRedNoticeByNumber, invoiceToRedValid , toredInvoice} from '@/service/sal/invoice'
export default {
  name: 'InvoiceBill',
  props: ['show', 'invoice','failRedInvoice','KPJL','recordBillingBlush','isCraft'],
  data() {
    return {
      isFastToredList:[
        {value: true, label: '是'},
        {value: false, label: '否'}
      ],
      flushedCauseList: [
        {value: '01',label: '开票有误'},
        {value: '02',label: '销货退回'},
        {value: '03',label: '服务终止'},
        {value: '04',label: '销售折让'},
      ],
      loading: true,
      stage: 'toBilling', // loading: 等待阶段, toBilling：发票待开，isBilling：发票开具中，batchBilling：批量开具
      issue: null,      // 待开发票项
      percentage: 0,    // 进度条初始值
      eInv: false,
      colors: [
        {color: '#f5222d', percentage: 30},
        {color: '#e6a23c', percentage: 60},
        {color: '#09BB07', percentage: 90},
      ],    // 进度条色域
      inventoryLoading: false,
    }
  },
  computed: {
    isDigitalInvoice(){
      return (this.issue.billingType == 'QDZP' || this.issue.billingType == 'QDPP') ?  true : false
    },
    // 根据开票设备和开票方式展示：发票号码、代码、库存
    isShowInfo() {
      if (this.issue.billingChannel == 'LS-JG-UKEY') {
        return true
      } else {
        switch (this.issue.billingChannel) {
          case 'HX-ZJ-JSP':
            return true
          case 'NN-JG-JSP':
            return true
          default:
            return false
        }
      }
    },
    ifShowNoCode() {
      switch (this.issue.billingChannel) {
        case 'HX-ZJ-JSP':
        case 'LS-JG-UKEY':
        case 'LS-ZJ-UKEY':
          return true
        default:
          return false
      }
    },
    dialogWidth() {
      switch (this.stage) {
        case 'toBilling':
        case 'isBilling':
          return '480px'
        default :
          return '580px'
      }
    },
    isSupportPrint() {
      return (['LS-JG-UKEY', 'HX-ZJ-JSP'].indexOf(this.issue.billingChannel) != -1 && ['Zzzp', 'Zzpp', 'SGZP'].indexOf(this.issue.billingType) != -1)
    },
    isShowRedNoticeNo() {
      return this.issue.billingFlag === 'Hp' && (this.issue.billingType === 'Zzzp' || this.issue.billingType === 'DZZP' || this.issue.billingType === 'QDZP' || this.issue.billingType === 'QDPP')
    },
    isRedInvoice() {
      return this.issue.billingFlag === 'Hp'
    },
  },
  created() {
    this.issue = JSON.parse(JSON.stringify(this.invoice))
    this.eInv = this.elecDecide()
    this.issue.toRedRemark = '01'
    this.issue.auToRedNotice = false
    if(this.issue.isRedInvoice) {
      this.issue.billingFlag = 'Hp'
    }
    // console.log('【确认开票】发票信息:', this.issue)
    this.eInv = this.elecDecide()
    if (this.issue instanceof Object) {
      this.handleGetInventory()
    } else {
      this.stage = 'batchBilling'
    }
    if(!this.isCraft) {
      this.issue.originInvoiceCode = this.issue.invoiceCode
      this.issue.originInvoiceNo = this.issue.invoiceNo
      this.issue.toRedRemark = this.issue.toRedRemark
    }
  },
  methods: {
    elecDecide() {
      return this.issue.billingType === 'QDZP' || this.issue.billingType === 'QDPP'
    },
    handlerSelect(){
      this.$forceUpdate()
    },
    handleTored () {
      toredInvoice({
        ...this.issue,
        invoiceId: this.issue.id,
        receiveEmail: this.issue.receiveEmail,
        redNoticeNo: this.issue.redNoticeNo,
        toRedRemark: this.issue.toRedRemark,

      }).then(res => {
        if (res.success) {
          this.$emit('update:show',false)
          this.$message.success('发票冲红成功')
          this.$emit('success')
          this.$parent.handleQuery()
        }
      })
    },
    async invoiceToRedValid(isPrint){
      if(this.issue.sumAmount > 0){
        this.billingSubmitAfter(isPrint);
        return;
      }
      const param = {
        invoiceId: this.issue.id,
        isFastTored: this.issue.auToRedNotice,
      }

      let res = await invoiceToRedValid(param)
      if(res.success){
        if(this.recordBillingBlush == 'blush') {
          this.handleTored()
        } else {
          this.billingSubmitAfter(isPrint)
        }
      }
    },
    // 查询
    async getRedNoticeByNumber(){
      let res = await getRedNoticeByNumber(this.issue.originInvoiceNo)
      // console.log(res)
      if(res.success){
        this.issue.redNoticeUuid = res.data.data.redNoticeUuid
        this.issue.redNoticeNo = res.data.data.redNoticeNo
        this.issue.toRedRemark = res.data.data.applyRemark
        console.log(this.issue)
      }
    },
    // 查询
    handlerInquire(){
      console.log(this.issue.originInvoiceNo,'this.issue.originInvoiceNo')
      if(this.issue.originInvoiceNo){
        this.getRedNoticeByNumber()
      }else {
        this.toast('请填写对应正数发票号码！', 'warning')
        return
      }
    },
    // 获取库存信息
    async handleGetInventory() {
      this.inventoryLoading = true
      const {success, data} = await getInventory({
        'orgId': this.issue.orgId,
        'equipmentId': this.issue.equipmentId,
        'billingType': this.issue.billingType
      })
      this.loading = false
      this.inventoryLoading = false
      if (success) {
        this.$set(this.issue, 'total', data.residueQuantity ? data.residueQuantity : 0)
        this.$set(this.issue, 'invoiceCode', data.invoiceCode)
        this.$set(this.issue, 'invoiceNo', data.invoiceNo)
        this.$set(this.issue, 'billingChannel', data.billingChannel)
      }
    },
    // 发票开具并打印
    handleBillingAndPrint() {
      if (!this.isSupportPrint) {
        return this.toast('当前设备类型不支持连开带打', 'warning')
      }
      this.handleInvoiceBillingConfirm('Y')
    },
    // 发票开具确认
    handleInvoiceBillingConfirm(isPrint) {
      this.$bus.$emit('handlerInvoiceData', this.issue)
      this.loading = true

      // 红票校验
      if (this.isRedInvoice) {
        // 红色通知单校验
        // if (this.isShowRedNoticeNo) {
        //   const redNoticeNo = this.issue.redNoticeNo
        //   if (typeof (redNoticeNo) == 'undefined' || redNoticeNo == '' || redNoticeNo == null) {
        //     this.toast('开具红票失败，红色通知单号不能为空！', 'warning')
        //     this.loading = false
        //     return
        //   }
        // }
        const originInvoiceCode = this.issue.originInvoiceCode
        let iss = originInvoiceCode == 'QDZP' || originInvoiceCode == 'QDPP' ? true : false
        if(iss){
          if (typeof (originInvoiceCode) == 'undefined' || originInvoiceCode == '' || originInvoiceCode == null) {
            this.toast('开具红票失败，对应正数发票代码不能为空！', 'warning')
            this.loading = false
            return
          }
        }

        const originInvoiceNo = this.issue.originInvoiceNo
        if (typeof (originInvoiceNo) == 'undefined' || originInvoiceNo == '' || originInvoiceNo == null) {
          this.toast('开具红票失败，对应正数发票号码不能为空！', 'warning')
          this.loading = false
          return
        }
        const redNoticeNo = this.issue.redNoticeNo
        console.log(this.issue,'this.issue')
        const redNoticeUuid = this.issue.redNoticeUuid
        if(!this.issue.auToRedNotice){
          if(redNoticeNo && redNoticeNo.trim().length == 0){
            this.toast('开具红票失败，对应红字通知单号不能为空！', 'warning')
            this.loading = false
            return
          }
          if(redNoticeUuid && redNoticeUuid.trim().length == 0 && (this.issue.BillingType == 'QDZP' ||  this.issue.BillingType == 'QDPP')){
            this.toast('开具红票失败，对应红字通知单UUID不能为空！', 'warning')
            this.loading = false
            return
          }
        }
      }

      let invoiceId = this.issue.id
      if (invoiceId != undefined && invoiceId != null && invoiceId != 0) {
        updateInvoiceRemark(this.issue).then(res => {
          if (res.success) {
            this.invoiceToRedValid(isPrint)
          } else {
            this.toast('请求开票失败，更新发票备注时出错！', 'warning')
          }
        })
      } else {
        this.invoiceToRedValid(isPrint)
      }
    },
    billingSubmitAfter(isPrint) {
      if (isPrint == 'Y') {
        this.$emit('billing-invoice', isPrint, (state) => {
          if (state) this.loading = false
        })
      } else {
        this.$emit('billing-invoice', isPrint, (state) => this.handleProgress(state))
      }

    },
    // 发票开具中 进度条
    handleProgress(state) {
      this.loading = false
      // 开票失败时不显示开具进度条！
      if (!state) return

      this.stage = 'isBilling'
      this.percentage = 0
      let progress = setInterval(() => {
        if (this.percentage >= 80) {
          clearInterval(progress)
          this.visible = false
          this.$emit('update:show', false)
          this.$message.success('请求开票成功，开具发票申请已提交！')
        } else {
          this.percentage += parseInt(Math.random() * 20)
        }
      }, 200)
    },
    // 关闭弹窗
    handleClose() {
      this.$emit('update:show', false)
    },
    handleBillingFlag() {
      return Number(this.issue.sumAmount) > 0 ? '蓝票' : '红票'
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog__body {
  padding: 30px 20px;
}

::v-deep .el-dialog__header {
  display: none;
}

.error {
  color: #F5222D !important;
}

.dialog-wrap {
  padding: 30px 20px;
}

.dialog-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;

  .title {
    color: #333333;
    font-size: 18px;
  }

  span {
    color: #999999;
    font-size: 14px;
  }
}

.dialog-main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  margin-bottom: 24px;
  font-size: 16px;
  color: #333333;

  .dialog-split-line {
    margin: 11px 0;
    border-bottom: 1px solid #E9E9E9;
  }

  .dialog-tip {
    font-size: 14px;
  }

  .row-first {
    margin-bottom: 14px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    ::v-deep .el-textarea__inner {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      padding-left: 10px;
    }
  }

  label {
    width: auto;
    white-space: nowrap;
    color: #666666;
    font-size: 14px;
  }

  span {
    flex: auto;
    color: #333333;
    font-size: 16px;
  }
}

.dialog-footer {
  text-align: right;
}
.inquire_style {
  color: #6987f9 !important;
  margin-left: 20px;
  margin-top: 4px;

}
</style>
