<template>
  <div class="content-wrap">
    <!--    <el-button type="primary" @click="sendMsg">嗨嗨嗨</el-button>-->
    <div class="content-select" v-if="isRetail">
      <label>当前组织：</label>
      <organization-select :is-add-all="true" :model="selectForm"></organization-select>
      <el-popover placement="bottom" @show="showsss" title="默认显示：" width="200" v-model="visible">
        <div>
          <el-radio style="margin-top: 12px" v-model="radio" label="1">所在组织数据</el-radio><br />
          <el-radio style="margin-top: 12px" v-model="radio" label="2">全部组织数据</el-radio>
          <div style="text-align: right; margin-top: 12px">
            <span style="margin-right: 12px; cursor: pointer" @click="visible = false">取消</span>
            <span style="color: #508edc; cursor: pointer" @click="conserveSet">保存</span>
          </div>
        </div>
        <span slot="reference" v-show="activeName == 'already'" style="margin-left: 12px; color: #1890ff">设置</span>
      </el-popover>
    </div>
    <div class="ed_class" v-if="activeName == 'wait' && elecType == '1'">
      <div style="margin-top: 7px">
        <label>本月总额度：</label>
        <span>{{ totalAmountLimit }}</span>
      </div>
      <div style="margin-top: 7px; margin-left: 20px">
        <label>可用剩余额度：</label>
        <span>{{ availableRemainingLimit }}</span>
      </div>
      <div class="refresh">
        <el-button icon="el-icon-refresh" circle @click="handleInitInventory"></el-button>
      </div>
    </div>
    <div :class="isRetail ? 'content-tab' : ''">
      <el-tabs v-model="activeName" @tab-click="handleInvoiceOpenTabsChange">
        <!-- <el-tab-pane label="原始订单" name="order" lazy>
          <retail-original-order :model="selectForm" v-if="activeName == 'order'"></retail-original-order>
        </el-tab-pane> -->
        <el-tab-pane label="待开发票" name="wait" lazy>
          <retail-wait-invoice @changeManual="handleChangeManual" v-if="isRetail && activeName == 'wait'" :ids="salInvoiceIds" @navigateToAlready="navigateToAlready"></retail-wait-invoice>
          <hotel-wait-invoice v-if="!isRetail && activeName == 'wait'" :ids="salInvoiceIds" @navigateToAlready="navigateToAlready"></hotel-wait-invoice>
        </el-tab-pane>
        <el-tab-pane label="手工开票" name="manual" lazy>
          <retail-manual-invoice ref="manual" v-if="isRetail" @navigateToAlready="navigateToAlready"></retail-manual-invoice>
          <hotel-manual-invoice ref="manual" v-else @navigateToAlready="navigateToAlready"></hotel-manual-invoice>
        </el-tab-pane>
        <el-tab-pane label="开票记录" name="already" lazy>
          <retail-already-invoice @changeManual="handleChangeManual" ref="retailAlreadyInvoice" @setOrgIdEmit="setOrgIdEmit" v-if="isRetail && activeName == 'already'" :model="selectForm"></retail-already-invoice>
          <hotel-already-invoice @changeManual="handleChangeManual" v-if="!isRetail && activeName == 'already'"></hotel-already-invoice>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
// 酒店行业
import HotelWaitInvoice from './hotel/HotelWaitInvoice';
import HotelManualInvoice from './hotel/HotelManualInvoice';
import HotelAlreadyInvoice from './hotel/HotelAlreadyInvoice';
// 零售行业
import RetailOriginalOrder from './retail/RetailOriginalOrder';
import RetailWaitInvoice from './retail/RetailWaitInvoice';
import RetailManualInvoice from './retail/RetailManualInvoice';
import RetailAlreadyInvoice from './retail/RetailAlreadyInvoice';

import OrganizationSelect from '@/components/widgets/OrganizationSelect';
import store from '@/store';
import { getCreditLimitByOrganization, getCreditByOrgId, getEquipmentByOrgId } from '@/service/invoice-open';

export default {
  name: 'InvoiceIssue',
  components: {
    OrganizationSelect,
    HotelWaitInvoice,
    HotelManualInvoice,
    HotelAlreadyInvoice,
    RetailWaitInvoice,
    RetailOriginalOrder,
    RetailManualInvoice,
    RetailAlreadyInvoice
  },
  data() {
    return {
      radio: '1',
      visible: false,
      activeName: '',
      elecType: '',
      selectForm: {
        orgId: localStorage.getItem('orgId') ? Number(localStorage.getItem('orgId')) : '',
        name: ''
      },
      salInvoiceIds: '[]',
      totalAmountLimit: '--',
      availableRemainingLimit: '--'
    };
  },
  watch: {
    'selectForm.orgId': {
      handler(value) {
        store.commit('invoiceIssue/SET_CURRENT_ORGANIZATION', value);
        // console.log(this.activeName, 'activeName');
        if (this.activeName == 'wait') {
          this.getCreditByOrgId();
        }
      },
      immediate: true
    }
  },
  computed: {
    isRetail() {
      return this.$store.state.user.industry == 'RETAIL';
    }
  },
  created() {
    this.activeName = localStorage.getItem('activeName') || 'manual';
    this.radio = localStorage.getItem('setOrgId');
    const invoiceIds = this.$route.query.salInvoiceIds;
    if (invoiceIds) {
      this.activeName = "wait";
      this.invoiceIds = window.atob(invoiceIds);
    }
  },
  mounted() {
    this.$bus.$on('elecType', (data) => {
      this.elecType = data;
      if (this.elecType == 1) {
        this.getCreditByOrgId();
      }
    });
  },
  beforeDestroy() {
    this.$bus.$off('elecType');
  },
  methods: {
    showsss() {
      // debugger;
      if (localStorage.getItem('setOrgId') != 2) {
        this.radio = '1';
      } else {
        this.radio = localStorage.getItem('setOrgId');
      }
      console.log(1, this.radio);
    },
    conserveSet() {
      if (this.radio == 1) {
        this.selectForm.orgId = JSON.parse(localStorage.getItem('orgId' || ''));
        console.log(this.selectForm.orgId, 'this.selectForm.orgId');
      } else {
        this.selectForm.orgId = 0;
      }
      localStorage.setItem('setOrgId', this.radio);
      this.toast('默认组织设置成功', 'success');
      this.visible = false;
      this.$nextTick(() => {
        this.$refs.retailAlreadyInvoice.handleQuery();
      });
    },
    setOrgIdEmit(data) {
      if (data == 0) {
        this.selectForm.orgId = data;
        this.radio = '2';
      } else {
        this.radio = '1';
      }
    },
    async getEquipmentByOrgId() {
      let res = await getEquipmentByOrgId({ orgId: this.selectForm.orgId });
      if (res.success) {
        this.elecType = res.data.elecType;
        if (this.elecType == '1') {
          this.getCreditByOrgId();
        }
      }
    },
    async getCreditByOrgId() {
      // let res = await getCreditByOrgId(this.selectForm.orgId);
      // // console.log(res, 'rrrrrsseee');
      // if (res.success) {
      //   this.totalAmountLimit = res.data.totalAmountLimit ? res.data.totalAmountLimit : '--';
      //   this.availableRemainingLimit = res.data.availableRemainingLimit ? res.data.availableRemainingLimit : '--';
      // }
    },
    async getCreditLimitByOrganization() {
      // let res = await getCreditLimitByOrganization(this.selectForm.orgId);
      // // console.log(res, 'res试试');
      // if (res.success) {
      //   this.totalAmountLimit = res.data.totalAmountLimit ? res.data.totalAmountLimit : '--';
      //   this.availableRemainingLimit = res.data.availableRemainingLimit ? res.data.availableRemainingLimit : '--';
      // }
    },
    handleInitInventory() {
      this.getCreditLimitByOrganization();
    },
    // 发送消息
    sendMsg() {
      // this.$socket.send('嗨嗨嗨');
      //json数据
      // this.$socket.sendObj({ name: '嗨嗨嗨' });
    },
    // 发票开具Tabs切换
    handleInvoiceOpenTabsChange(tab) {
      this.activeName = tab.name;
      localStorage.setItem('activeName', tab.name);
    },
    // 开票记录-手工开票（复制发票）
    handleChangeManual() {
      this.activeName = 'manual';
      this.$nextTick(() => {
        this.$refs.manual.initCopyData();
      });
    },
    navigateToAlready() {
      this.activeName = 'already';
    }
  }
};
</script>

<style lang="scss" scoped>
.content-select {
  width: 340px;
  position: relative;
  top: 16px;
  left: calc(100% - 350px);
  z-index: 200;

  label {
    color: #333333;
    font-weight: 400;
    font-size: 16px;
  }

  ::v-deep .el-input__inner {
    border: none;
    border-radius: 0;
  }
}

.content-tab {
  margin-top: -24px;
  z-index: 1;
}

::v-deep .el-tabs__item {
  margin-top: 7px;
  color: #333333;
  font-weight: 400;
  font-size: 16px;
}

::v-deep .el-tabs__item.is-active {
  color: #3d94ff;
}
.content-wrap {
  position: relative;
}
.ed_class {
  z-index: 3333;
  position: absolute;
  z-index: 200;
  width: 500px;
  top: 16px;
  left: calc(100% - 780px);
  display: flex;
  color: #e89f68;
  font-size: 12px;
}
.refresh {
  color: #3d94ff;
  font-size: 20px;
  margin-left: 10px;
  .el-button {
    width: auto;
    height: auto;
    line-height: 1;
  }
  ::v-deep .el-button.is-circle {
    padding: 7px;
  }
}
</style>
