<template>
  <el-form class="content-wrap"
           :model="buyerEmail"
           :rules="rules"
           ref="rules"
           label-width="80px"
           @keyup.down.native="focusAround(true)"
           @keyup.up.native="focusAround(false)">
    <el-row>
      <el-col :span="12" v-if="eletype == 0">
        <el-form-item label="税控设备">
          <span>分机编号: {{equipment.extensionNo | fmtNotValue}}</span>
        </el-form-item>
      </el-col>
      <el-col :span="12" v-if="eletype == 1 ">
        <el-form-item label="开票通道">
          <span>分机编号: {{equipment.billingChannel | fmtbillingChannel(billingChannels)}}</span>
        </el-form-item>
      </el-col>
      <el-col :span="12" v-if="eletype == 0 ">
        <el-form-item label="开票方式">
          <span>{{equipment.billingChannel | fmtbillingChannel(billingChannels)}}</span>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-form-item prop="receiveEmail" label="电票交付">
        <div class="address-area">
          <el-input id="receiveEmail" v-model="buyerEmail.receiveEmail" placeholder="请输入邮箱"></el-input>
          <div style="width: 15px;"></div>
          <el-input id="receivePhone" v-model="buyerEmail.receivePhone" placeholder="请输入手机号码"></el-input>
        </div>
      </el-form-item>
    </el-row>
    <el-row>
      <el-form-item label="纸票邮寄" v-if="eletype == 0 ">
        <div class="address-area">
          <el-form-item prop="expressPhone">
            <el-input id="expressPhone" v-model="buyerEmail.expressPhone" placeholder="请输入联系电话"></el-input>
          </el-form-item>
          <el-form-item prop="expressContact">
            <el-input id="expressContact" class="address-n" v-model="buyerEmail.expressContact"
                      placeholder="请输入联系人姓名"></el-input>
          </el-form-item>
          <el-form-item prop="expressAddress">
            <el-input id="expressAddress" class="address-e" v-model="buyerEmail.expressAddress"
                      placeholder="请输入邮寄地址"></el-input>
          </el-form-item>
        </div>
      </el-form-item>
    </el-row>
  </el-form>
</template>

<script>
import RegexLib from '@/assets/js/regex-lib'

export default {
  name: 'InvoiceOpenUserAddress',
  props: ['buyerEmail', 'equipment'],
  data () {
    return {
      billingType: 'Dzpp',
      eletype:'',
      focusList: ['receiveEmail', 'expressPhone', 'expressContact', 'expressAddress'],
      rules: {
        receiveEmail: [{ validator: this.checkEmail, trigger: 'blur' }],
        expressPhone: [{ validator: this.checkPhone, trigger: 'blur' }],
      },
      billingChannels: []
    }
  },
  created() {
    // 给所有开票方式赋值
    this.getDicts('equ_billing_mode').then(({ data }) => this.billingChannels = data)
  },
  filters: {
    fmtNotValue(val){
      return val ? val : "--"
    },
    fmtbillingChannel (val,billingChannels) {
      let billingChannel = billingChannels.find(item => item.value == val)
      if (billingChannel != '' && billingChannel != undefined) return billingChannel.label
    }
  },
  mounted(){
    this.$bus.$on('eletype',(data) => {
      this.eletype = data
    })
  },
  methods: {
    // 键盘事件 上/下
    focusAround (type) {
      let domId = document.activeElement.id
      let index = this.focusList.findIndex(i => i === domId)
      if (type) {
        if (index < this.focusList.length - 1) {
          index++
          domId = this.focusList[index]
        }
      } else {
        if (index > 0) {
          index--
          domId = this.focusList[index]
        }
      }
      document.getElementById(domId).focus()
    },
    // 校验邮箱
    checkEmail (rule, value, callback) {
      if (value == '' || value == null || value.length == 0) {
        return callback()
      }
      if (!RegexLib.Email.test(value)) {
        this.$emit('handleDZPPcheck', false)
        return callback(new Error('请输入正确的邮箱地址!'))
      }
      this.$emit('handleDZPPcheck', true)
      return callback()
    },
    // 校验联系电话
    checkPhone (rule, value, callback) {
      if (value == '' || value == null || value.length == 0) {
        return callback()
      }
      if (!RegexLib.Telephone.test(value)) {
        callback(new Error('请输入正确的电话号!'))
      }
      return callback()
    },
    resetForm () {
      this.$refs.rules.resetFields()
      this.buyerEmail.receivePhone = ''
    },
    changeBillingType (val) {
      this.billingType = val
    },
    handleChangedRadio (value) {
      this.$emit('handleChangedRadio', value)
    },
    // 跳转设备管理
    handleNavigatorToEquipment () {
      this.$router.push('/system/equipment')
    }
  }
}
</script>

<style scoped lang="scss">
  .content-wrap {
    height: auto;
    width: auto;
    padding-left: 10px;
    text-align: left;

    .address-area {
      display: flex;
      width: 440px;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;

      .address-n {
        margin-left: 16px;
      }

      .address-e {
        margin-top: 16px;
        width: 416px;
      }
    }
  }

  .required {
    display: inline-block;
    position: absolute;
    margin-top: 4px;
    color: #B60000;
    font-size: 14px;
  }

  ::v-deep .el-row {
    margin: 16px 0;
  }

  ::v-deep .el-form-item__label {
    color: #A7643B;
    text-align: right;
  }

  ::v-deep .el-radio {
    font-weight: 400;
    color: #333333;
  }

  ::v-deep .el-form-item__error {
    top: -12px;
    font-size: 12px;
  }

  ::v-deep .el-input__inner {
    padding: 0;
  }

  ::v-deep .el-radio__input.is-checked + .el-radio__label {
    color: #333333;
  }

  .tooltip-span {
    color: #1890ff;
    line-height: 22px;
    font-weight: 500;
  }

  .tooltip-span:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .tooltip-icon {
    display: inline-block;
    margin-left: 16px;
    color: #F5222D;
  }

  .tooltip-slot {
    display: block;
    width: 360px;
  }
</style>
