<template>
  <el-dialog append-to-body :visible.sync="show" :show-close="false" v-if="show" :close-on-click-modal="false" :close-on-press-escape="false" title="修改明细信息" @close="$emit('update:show', false)" width="500px">
    <div class="invoice-title-form">
      <el-form :model="invoiceItem" ref="invoiceForm">
        <div class="" style="margin-top: 10px;">
          <div class="">
            <label style="margin-right: 10px">价税合计</label>
            <el-input type="number" v-model="invoiceItem.sumAmount" :precision="2" label="价税合计"></el-input>
          </div>
          <div style="color: red;margin-top: 10px;margin-bottom: 10px;font-size: 16px">修改价税合计之后，会根据价税合计再次反算金额、税额</div>
        </div>
      </el-form>
    </div>
    <div slot="footer">
      <el-button type="default" @click="$emit('update:show', false)">取消</el-button>
      <el-button type="primary" @click="handleUpdate">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {invoiceDetailRedact, updateInvoice} from '@/service/sal/invoice';

export default {
  name: 'InvoiceItemAmountEdit',
  props: ['show', 'invoiceItem'],
  data() {
    return {

    };
  },
  methods: {
    handleItemChange(type, num) {

    },
    handleUpdate() {
      invoiceDetailRedact(this.invoiceItem).then((res) => {
        if (res.success) {
          this.toast('修改成功', 'success', () => {
            this.$emit('update:show', false);
          });
          this.$emit('success');
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.invoice-title-form {
  padding: 0 24px;
}

.edit-select {
  display: flex;
  flex-direction: column;

  .edit-select-item {
    margin-bottom: 56px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .edit-input .el-input {
      width: 450px;
    }
  }

  .edit-select-foot {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
}

::v-deep .el-form-item__label {
  padding: 0px 0px 0px 0px;
  line-height: 24px;
}

::v-deep .el-form-item__error {
  top: 64px;
}

::v-deep .el-dialog__body {
  margin-top: 0px;
}
</style>
