import { get, post, download, put, del } from './index.js';

/**
 * 待开发票列表(分页)
 * @param {*} data
 */
export const waitList = (data) => {
  return post('/billing/trade/waitList', data).then((res) => res);
};
/**
 * 开具库存信息
 * @param {*} data
 */
export const getInventory = (data) => {
  return post('/invoice/billing/getInventory', data).then((res) => res);
};
/**
 * 发票开具
 * @param {*} data
 */
export const invoiceBilling = (data) => {
  return post('/billing/trade/invoiceBilling/', data).then((res) => res);
};
/**
 * 获取待开发票明细信息
 * @param {*} data
 */
// export const selectInvoice = (param) => {
//   return get('/billing/trade/selectInvoice/' + param).then(res => res)
// }
// /**
//  * 合并发票
//  * @param {*} data
//  */
// export const mergeInvoice = (data) => {
//   return post('/billing/trade/mergeInvoice', data).then(res => res)
// }
/**
 * 拆分明细
 * @param {*} data
 */
export const splitInvoiceItem = (data) => {
  return post('/billing/trade/items/splitInvoiceItem', data).then((res) => res);
};
/**
 * 合并明细
 * @param {*} data
 */
export const mergeInvoiceItem = (data) => {
  return post('/billing/trade/items/mergeInvoiceItem', data).then((res) => res);
};
// /**
//  * 拆分单据
//  * @param {*} data
//  */
// export const mergeInvoice = (data) => {
//   return post('/billing/trade/items/mergeInvoice', data).then(res => res)
// }
/**
 * 已开发票列表(分页)
 * @param {*} data
 */
export const getInvoiceAlreadyListByPage = (data) => {
  return post('/invoice/billing/getInvoiceAlreadyListByPage', data).then((res) => res);
};
/**
 * 已开发票统计
 */
export const getAlreadyInvoicesCount = (param) => {
  return post('/invoice/billing/statistics', param).then((res) => res);
};
/**
 * 获取已开发票明细
 * @param {*} data
 */
// export const selectInvoice = (data) => {
//   return get('/invoice/billing/selectInvoice/' + data).then(res => res)
// }
/**
 * 冲红
 * @param {*} data
 */
export const toredInvoice = (data) => {
  return post('/invoice/billing/toredInvoice', data).then((res) => res);
};
/**
 * 作废
 * @param {*} data
 */
export const revokeInvoice = (data) => {
  return get('/invoice/billing/revokeInvoice/' + data).then((res) => res);
};
/**
 * 打印
 * @param {*} data
 */
export const printInvoice = (data) => {
  return post('/invoice/billing/printInvoice', data).then((res) => res);
};
/**
 * 诺诺-打印发票
 * @param data
 * @returns {Promise<unknown>}
 */
export const nNPrintInvoice = (data) => {
  return post('/invoice/billing/nNPrintInvoice', data).then((res) => res);
};

/**
 * 开具（失败重开）
 * @param {*} data
 */
export const rebillInvoice = (data) => {
  return get('/invoice/billing/rebillInvoice/' + data).then((res) => res);
};
/**
 * 模糊搜索商家名称（发票抬头）
 * @param param
 * @returns {Promise<unknown>}
 */
export const getBuyerNames = (param) => {
  return get('/invoice/manual/getBuyerName', param).then((res) => res);
};

export const getBuyerTaxNo = (param) => {
  return get('/invoice/manual/getBuyerTaxNo', param).then((res) => res);
};

/**
 * 手工发票开具
 * @param data
 * @returns {Promise<unknown>}
 */
export const manualInvoiceIssue = (data) => {
  return post('/invoice/manual/billingManualInvoice', data).then((res) => res);
};

/**
 * 根据组织Id获取默认税盘
 * @param param
 * @returns {Promise<unknown>}
 */
export const getEquipmentByOrgId = (param) => {
  return get('/invoice/manual/getEquipmentByOrgId', param).then((res) => res);
};

/**
 * 开票记录导出
 * @param data
 * @returns {Promise<unknown>}
 */
export const exportAlreadyInvoices = (data) => {
  return download('/invoice/billing/exportInvoiceAlreadyList', data).then((res) => res);
};

/**
 * 待开发票修改抬头
 * @param data
 * @returns {Promise<unknown>}
 */
export const updateInvoiceBuyer = (data) => {
  return post('/billing/trade/updateInvoiceBuyer', data).then((res) => res);
};

/**
 * 开票记录、电子发票下载pdf
 * @param data
 * @returns {Promise<unknown>}
 */
export const downloadInvoice = (data) => {
  return download('/invoice/billing/downloadInvoice', data).then((res) => res);
};
/**
 * 待开发票删除
 * @param data
 * @returns {Promise<unknown>}
 */
export const deleteBillingTrade = (data) => {
  return get('/billing/trade/deleteBillingTrade/' + data).then((res) => res);
};
/**
 * 开票记录删除
 * @param data
 * @returns {Promise<unknown>}
 */
export const batchDeleteInvoice = (data) => {
  return get('/invoice/billing/batchDeleteInvoice/' + data).then((res) => res);
};

/**
 * 待开发票校验拆分
 * @param param
 * @returns {Promise<unknown>}
 */
export const checkDeleted = (param) => {
  return get('/billing/trade/delete/check/' + param).then((res) => res);
};

/**
 * 校验发票限额
 * @param data
 * @returns {Promise<unknown>}
 */
export const checkInvoiceLimit = (data) => {
  return post('/invoice/billing/checkInvoiceLimit', data).then((res) => res);
};

/**
 * 校验发票限额
 * @param data
 * @returns {Promise<unknown>}
 */
export const getOverQuotaSplitDetails = (param) => {
  return post('/billing/trade/getOverQuotaSplitDetails', param).then((res) => res);
};

/**
 * 获取开票设置信息
 * @param param
 */
export const getBillingOpenPersonSetting = (param) => {
  return get('/invoice/manual/billingConfig', param).then((res) => res);
};

/**
 * 修改开票人设置信息
 * @param param
 * @returns {Promise<any | never>}
 */
export const saveOrUpdateBillingOpenPersonMsg = (param) => {
  return post('/invoice/manual/billingConfig', param).then((res) => res);
};

/**
 * 校验发票开具状态，返回开具方式
 * @param param
 */
export const checkBillingInvoiceStatusReturnbillingChannel = (param) => {
  return get(`/invoice/billing/checkBillingInvoiceStatus/${param}`).then((res) => res);
};

/**
 * 获取当前用户默认开票人设备
 * @param param
 * @returns {Promise<any | never>}
 */
export const getCurrentUserDefaultConfig = (param) => {
  return get('/invoice/manual/defaultConfig', param).then((res) => res);
};

/**
 * 分页查找红字信息表信息
 * @param param
 * @returns {Promise<any | never>}
 */
export const getRedNoticeListByPage = (param) => {
  return post('/invoice/redNotice/list', param).then((res) => res);
};

/**
 * 分页查找红字信息表信息（全电）
 * @param param
 * @returns {Promise<any | never>}
 */
export const getRedNoticeByPage = (param) => {
  return post('/invoice/redNotice/page', param).then((res) => res);
};

/**
 * 全电红色通知单申领结果单条查询（全电）
 * @param param
 * @returns {Promise<any | never>}
 */
export const handleApplyResultSingleQuery = (param) => {
  return post('/invoice/redNotice/sync/', param).then((res) => res);
};

/**
 * 分页查找发送给我的红字信息表信息（全电）
 * @param param
 * @returns {Promise<any | never>}
 */
export const getRedNoticeSendMeList = (param) => {
  return post('/invoice/redNotice/list', param).then((res) => res);
};

/**
 * 更具发票代码和号码查询发票信息
 * @param param
 * @returns {Promise<any | never>}
 */
export const getBillingInvoiceByCodeAndNo = (param) => {
  return get('/invoice/redNotice/billingInvoice', param).then((res) => res);
};

/**
 * 查询发票是否已申请过红字信息表
 * @param param
 */
export const getRedNoticeByBillingInvoiceId = (param) => {
  return get('/invoice/redNotice/redNotice/' + param).then((res) => res);
};

/**
 * 提交申请
 * @param param
 * @returns {Promise<any | never>}
 */
export const submitApply = (param) => {
  return post('/invoice/redNotice', param).then((res) => res);
};

/**
 * 查询审核状态
 * @param param
 * @returns {Promise<any | never>}
 */
export const getAuditStatus = (param) => {
  return get('/invoice/redNotice/auditStatus/' + param).then((res) => res);
};

/**
 * 全电红字通知单提交申请
 * @param param
 * @returns {Promise<any | never>}
 */
export const fullElecApplySubmit = (param) => {
  return post('/invoice/redNotice/full/elec/apply', param).then((res) => res);
};

/**
 * 获取已开发票信息
 * @param param
 * @returns {Promise<T>}
 */
export const getBillingInvoice = (param) => {
  return get(`/invoice/billing/${param}`).then((res) => res);
};
/**
 * 查询开具设备
 * @param param
 * @returns {Promise<any | never>}
 */
export const listEquipmentByOrg = (id) => {
  return get(`/equipment/org/list/${id}`).then((res) => res);
};

/**
 * 获取发票明细数量
 *
 * @param data
 * @returns {Promise<unknown>}
 */
export const getInvoiceItemNumbers = (data) => {
  return get('/invoice/billing/getInvoiceItemNumbers/' + data).then((res) => res);
};

/**
 * 获取红色通知单详情
 * @param {*} data
 */
export const getBillingRedNoticeDetails = (data) => {
  return get('/invoice/redNotice/billingRedNotice/details/' + data).then((res) => res);
};

/**
 * 全电发送给我的确认单详情
 * @param {*} data
 */
export const getBillingRedNoticeSendMeDetails = (data) => {
  return get('/invoice/redNotice/redNotice/sendMe/details/' + data).then((res) => res);
};

/**
 * 全电发送给我的确认单详情
 * @param {*} data
 */
export const handleRedNoticeRepeal = (data) => {
  return post('/invoice/redNotice/repeal/', data).then((res) => res);
};

// 同步数据 -- 新增
export const redNoticeSyncData = (data) => {
  return post('/invoice/redNotice/syncData', data).then((res) => res);
};

/**
 *
 * @param {*} data
 */
export const handleRedNoticeConfirmBatch = (data) => {
  return post('/invoice/redNotice/confirm/batch', data).then((res) => res);
};

/**
 * 查询确认单 确认结果
 * @param {*} data
 */
export const handleRedNoticeConfirm = (data) => {
  return post('/invoice/redNotice/confirm', data).then((res) => res);
};

export const handleRedNoticeSendMeFullElecSyncStatus = (data) => {
  return post('/invoice/redNotice/full/elec/sync/status', data).then((res) => res);
};

export const handleRedNoticeFullElecWaitConfirmSendMePull = (data) => {
  return post('/invoice/redNotice/sendMe/data/pull', data).then((res) => res);
};

/**
 * 获取红色通知单结果
 * @param {*} data
 */
export const getBillingRedNoticeApplyResult = (data) => {
  return post('/invoice/redNotice/full/elec/result/', data).then((res) => res);
};

/**
 * 待确认全电红字通知单结果请求
 * @param {*} data
 */
export const handleRedNoticeResultRequest = (data) => {
  return post('/invoice/redNotice/full/elec/sendme/sync/request', data).then((res) => res);
};

/**
 * 待确认全电红字通知单结果请求
 * @param {*} data
 */
export const handleRedNoticePullSendMeRequest = (data) => {
  return post('/invoice/redNotice/pull', data).then((res) => res);
};

/**
 * 获取全电红字确认单查询结果请求
 * @param {*} data
 */
export const getBillingRedNoticeResultRequest = (data) => {
  return post('/invoice/redNotice/batch/sync', data).then((res) => res);
};

/**
 * 批量导入模版下载
 * @returns {Promise<T>}
 */
export const downloadTemplate = () => {
  return download('/billing/trade/downloadTemplate').then((res) => res);
};

/**
 * 批量上传发票明细
 * @param data
 * @returns {Promise<T>}
 */
export const invoiceUploadBatch = (data) => {
  return post('/sal/ori/trade/item/import', data, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => res);
};

/**
 * 发票批量开具
 * @param data
 * @returns {Promise<T>}
 */
export const batchBillInvoice = (data) => {
  return post('/billing/trade/billing/batch', data).then((res) => res);
};

/**
 * 发票打印
 * @param {*} data
 */
export const printInovice = (data) => {
  return post('/invoice/billing/print', data).then((res) => res);
};

/**
 * 发票交付
 * @param data
 */
export const deliverInvoice = (data) => {
  return post('/invoice/billing/deliver', data).then((res) => res);
};

/**
 * 诺诺发票 - 同步开票结果
 * @param param
 * @returns {Promise<T>}
 */
export const syncInvoice = (param) => {
  return get('/invoice/billing/syncData/' + param).then((res) => res);
};

/**
 * 待开发票备注修改
 * @param param
 * @returns {Promise<T>}
 */
export const updateBillingTrade = (param) => {
  return put('/billing/trade', param).then((res) => res);
};

/**
 * 待开发票统计
 */
export const getBillingTradeStatistics = (param) => {
  return post('/billing/trade/statistics', param).then((res) => res);
};

/**
 * 根据订单编号查询订单信息
 *
 * @param param
 * @returns {Promise<unknown>}
 */
export const queryRemoteData = (param) => {
  return post('/invoice/manual/queryRemoteData', param).then((res) => res);
};

/**
 * 根据订单编号查询订单信息
 *
 * @param param
 * @returns {Promise<unknown>}
 */
export const getOriginData = (param) => {
  return post('/invoice/manual/originData', param).then((res) => res);
};

/**
 * 待开发票批量删除
 * @param param
 * @returns {Promise<unknown>}
 */
export const checkBatchDeleted = (param) => {
  return post('/billing/trade/checkBatchDeleted', param).then((res) => res);
};
export const deleteBillingBatch = (param) => {
  return del('/billing/trade/deleteBillingBatch', param).then((res) => res);
};

/**
 * 手工开票-扫码待开查询
 * @param param
 * @returns {Promise<unknown>}
 */
export const getScanWaitList = (param) => {
  return get('/store/request/page', param).then((res) => res);
};
/**
 * 手工开票-修改扫码待开 发票状态
 * @param param
 * @returns {Promise<unknown>}
 */
export const editScanWaitStatus = (param) => {
  return post('/store/request/edit', param).then((res) => res);
};

//授信额度
export const getCreditLimitByOrganization = (param) => {
  return get('/sal/invoice/creditLimitByOrganization/' + param).then((res) => res);
};

// 获取授信额度本地
export const getCreditByOrgId = (param) => {
  return get('/sal/credit/getCreditByOrgId/' + param).then((res) => res);
};

// 订单管理
export const getTradeList = (param) => {
  return get('/sal/ori/trade/page', param).then((res) => res);
};
// 订单作废
export const tradeRevoke = (param) => {
  return post('/sal/ori/trade/revoke', param).then((res) => res);
};
// 订单数据汇总
export const tradeSummary = (param) => {
  return get('/sal/ori/trade/summary', param).then((res) => res);
};
