<template>
  <div class="seller-info">
    <el-row class="seller-item row-first">
      <label>名&nbsp;&nbsp;称:</label>
      <span>{{ info.sellerName }}</span>
    </el-row>
    <el-row class="seller-item row-first">
      <label>纳税人识别号:</label>
      <span>{{ info.sellerTaxNo }}</span>
    </el-row>
    <el-row class="seller-item row-first">
      <label>地址、电话:</label>
      <el-tooltip placement="top" :content="info.sellerAddressTel">
        <span class="under-row" @click="handleUpdate">{{ info.sellerAddressTel | fmtLongValue }}</span>
      </el-tooltip>
    </el-row>
    <el-row class="seller-item">
      <label>开户行及账号:</label>
      <el-tooltip placement="top" :content="info.sellerBankAccount">
        <span class="under-row" @click="handleUpdate">{{ info.sellerBankAccount | fmtLongValue }}</span>
      </el-tooltip>
    </el-row>
    <!-- 编辑销售/购买方信息弹窗 -->
    <el-dialog append-to-body :title="updateTitle" :visible.sync="visible" width="480px" @close="handleCancel">
      <el-form :model="info" status-icon ref="ruleForm" label-width="140px">
        <el-row>
          <el-col :span="18">
            <el-form-item label="地址、电话:">
              <el-input v-model="info.sellerAddressTel" maxlength="200" style="width: 300px" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="18" style="margin-top: 20px">
            <el-form-item label="开户行及账号:">
              <el-input v-model="info.sellerBankAccount" maxlength="200" style="width: 300px" placeholder="请输入内容"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer" style="margin-top: 20px">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">保 存</el-button>
      </div>
    </el-dialog>
    <!-- 确认将销售方信息设为默认开票信息弹窗 -->
    <el-dialog append-to-body title="提示" :visible.sync="isDefault" width="30%" @close="isDefault = false">
      <div style="margin-bottom: 30px">
        <span style="font-size: medium">是否将当前信息设为默认开票信息</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isDefault = false">取 消</el-button>
        <el-button type="primary" @click="handleSetDefault">确 认</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getOrgOwnConfig, setOrgOwnConfig } from '@/service/system/config';

export default {
  name: 'InvoiceOpenSellerInfo',
  filters: {
    fmtLongValue(val) {
      if (val) return val.length > 24 ? val.substring(0, 24) + '...' : val;
    }
  },
  props: {
    billingType: {
      type: String,
      default: 'Dzpp'
    },
    orgId: {
      type: Number,
      default: null
    },
    info: {
      type: Object,
      default: () => {
        return {
          sellerName: '',
          sellerTaxNo: '',
          sellerAddressTel: '',
          sellerBankAccount: ''
        };
      }
    }
  },
  computed: {
    updateTitle() {
      return this.type == 'SGZP' || this.type == 'SGDP' ? '购买方信息修改' : '销售方信息修改';
    }
  },
  data() {
    return {
      orgList: this.$store.state.user.organizations,
      isDefault: false,
      visible: false,
      // 修改弹窗内信息
      sellerInfoUpdate: {
        oldSellerAddressTel: '',
        oldSellerBankAccount: ''
      }
    };
  },
  watch: {
    // 切换组织 - 更新销方信息
    orgId: {
      handler(val, oval) {
        let item = this.orgList.find((i) => i.id == val);
        this.info.sellerName = item.name;
        this.info.sellerTaxNo = item.taxNo;
        this.info.sellerAddressTel = item.addressTel;
        this.info.sellerBankAccount = item.bankAccount;

        oval && this.handleGetSellerSetting();
      },
      immediate: true
    }
  },
  methods: {
    // 查询组织信息是否修改
    async handleGetSellerSetting() {
      let params = {
        orgId: this.orgId,
        configs: { BILL_SELLER_BA: '', BILL_SELLER_AT: '' }
      };
      const { success, data } = await getOrgOwnConfig(params);
      if (success) {
        let configs = data?.configs;
        if (configs && configs['BILL_SELLER_BA']) {
          this.info.sellerBankAccount = configs['BILL_SELLER_BA'];
        }
        if (configs && configs['BILL_SELLER_AT']) {
          this.info.sellerAddressTel = configs['BILL_SELLER_AT'];
        }
      }
    },
    //开启修改弹窗
    handleUpdate() {
      this.visible = true;
      this.sellerInfoUpdate.oldSellerAddressTel = this.info.sellerAddressTel;
      this.sellerInfoUpdate.oldSellerBankAccount = this.info.sellerBankAccount;
    },
    // 点击取消
    handleCancel() {
      if (!this.isDefault) {
        this.info.sellerAddressTel = this.sellerInfoUpdate.oldSellerAddressTel;
        this.info.sellerBankAccount = this.sellerInfoUpdate.oldSellerBankAccount;
      }
      this.visible = false;
    },
    // 点击保存
    handleConfirm() {
      if (this.info.sellerAddressTel == this.sellerInfoUpdate.oldSellerAddressTel && this.info.sellerBankAccount == this.sellerInfoUpdate.oldSellerBankAccount) {
        this.toast('与原信息一致，无需修改！', 'error');
      } else {
        this.isDefault = true;
      }
      this.handleCancel();
    },
    //设为当前组织默认信息
    async handleSetDefault() {
      const params = {
        configs: {
          BILL_SELLER_AT: this.info.sellerAddressTel,
          BILL_SELLER_BA: this.info.sellerBankAccount
        },
        orgId: this.orgId
      };

      const { success } = await setOrgOwnConfig(params);
      if (success) {
        console.log('params', params);
        this.isDefault = false;
        this.toast('修改成功', 'success');
      } else {
        this.isDefault = false;
        this.info.sellerAddressTel = this.sellerInfoUpdate.oldSellerAddressTel;
        this.info.sellerBankAccount = this.sellerInfoUpdate.oldSellerBankAccount;
        this.toast('修改失败', 'error');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.seller-info {
  display: block;
  padding: 24px;

  label {
    display: inline-block;
    min-width: 100px;
  }

  .row-first {
    margin-bottom: 24px;
  }

  .seller-item {
    display: flex;
    flex-direction: row;

    .under-row {
      display: inline-block;
      min-width: 300px;
      color: #333333;
      border-bottom: 1px solid #333333;
    }

    .under-row:hover {
      cursor: pointer;
      color: #3d94ff;
    }
  }
}
</style>
