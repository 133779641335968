<template>
  <div class="content-wrap">
    <div class="content-select">
      <div class="select-content">
        <el-form
          class="select-form"
          ref="queryForm"
          :inline="true"
          :model="queryForm"
          label-width="100px"
        >
          <el-form-item label="税控设备" prop="equipmentExtensionNos">
            <el-select
              v-model="queryForm.equipmentExtensionNos"
              clearable
              multiple
              filterable
              collapse-tags
              placeholder="请选择税控设备"
            >
              <el-option
                v-for="item in equipments"
                :key="item.id"
                :label="item | fmtEqpName"
                :value="item.extensionNo"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="开票日期" prop="billedRange">
            <el-date-picker
              size="mini"
              v-model="billedRange"
              clearable
              type="daterange"
              range-separator="~"
              value-format="yyyy-MM-dd"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </el-form-item>

          <el-form-item label="发票状态" prop="invoiceStatus">
            <el-select
              v-model="queryForm.invoiceStatuses"
              clearable
              multiple
              filterable
              collapse-tags
              placeholder="请选择发票状态"
            >
              <el-option
                v-for="item in invoiceStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="发票号码" prop="invoiceNo">
            <el-input
              v-model.trim="queryForm.invoiceNo"
              clearable
              placeholder="请输入发票号码"
              maxlength="30"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>

          <el-form-item
            label="订单编号"
            prop="sheetCode"
            v-show="selectExpended"
          >
            <el-input
              v-model.trim="queryForm.sheetCode"
              clearable
              placeholder="请输入订单编号"
              maxlength="32"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>

          <el-form-item
            label="业务单号"
            prop="businessCode"
            v-show="selectExpended"
          >
            <el-input
              v-model.trim="queryForm.businessCode"
              clearable
              placeholder="请输入业务单号"
              maxlength="32"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>

          <el-form-item
            label="购方名称"
            prop="buyerName"
            v-show="selectExpended"
          >
            <el-input
              v-model.trim="queryForm.buyerName"
              clearable
              placeholder="请输入购方名称"
              maxlength="30"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>

          <el-form-item
            label="流水编号"
            prop="billSerialNo"
            v-show="selectExpended"
          >
            <el-input
              v-model.trim="queryForm.billSerialNo"
              clearable
              placeholder="请输入流水编号"
              maxlength="20"
              @keyup.enter.native="handleQuery"
            />
          </el-form-item>

          <el-form-item
            label="所属部门"
            prop="deptCode"
            v-show="selectExpended"
          >
            <el-select
              v-model="queryForm.deptCode"
              clearable
              placeholder="请选择所属部门"
            >
              <el-option
                v-for="item in departmentList"
                :key="item.id"
                :label="item.name"
                :value="item.uniqueCode"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="操作人员"
            prop="creatorName"
            v-show="selectExpended"
          >
            <el-input
              v-model.trim="queryForm.creatorName"
              clearable
              placeholder="请输入操作人员"
            />
          </el-form-item>

          <el-form-item
            label="价税合计"
            prop="sumAmount"
            v-show="selectExpended"
          >
            <el-input
              v-model.trim="queryForm.sumAmount"
              clearable
              type="number"
              placeholder="请输入价税合计"
            />
          </el-form-item>

          <el-form-item
            class="invoice-tags"
            label="发票标签"
            prop="tags"
            v-show="selectExpended"
          >
            <el-select
              v-model="tags"
              class="invoice-tags-select"
              filterable
              :multiple-limit="10"
              clearable
              multiple
              collapse-tags
              placeholder="请选择发票标签"
            >
              <el-option
                v-for="item in businessPractice"
                :key="item.code"
                :label="item.name"
                :value="item.code"
              ></el-option>
            </el-select>
            <el-select
              v-model="queryForm.tagCondition"
              class="invoice-tags-condition"
              style="width: 50px"
              placeholder="请选择查询条件"
            >
              <el-option label="且" value="AND"></el-option>
              <el-option label="或" value="OR"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item style="margin-left: 32px">
            <select-button
              show-status
              @list-close="handleListClose"
              @list-show="handleListShow"
              @select="handleQuery"
              @reset="handleReset"
            ></select-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="content-main">
      <!-- 功能按钮-->
      <div class="main-top">
        <div style="display: flex; justify-content: flex-start">
          <span>票据列表</span>
          <el-popover placement="right" width="80" trigger="click">
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
              >全选
            </el-checkbox>
            <div class="part"></div>
            <el-checkbox-group
              v-model="colOptions"
              @change="handleCheckedCitiesChange"
            >
              <el-checkbox
                v-for="item in colSelect"
                :label="item"
                :key="item"
                style="display: block; margin-top: 6px"
              ></el-checkbox>
            </el-checkbox-group>
            <div slot="reference" class="main-top" style="cursor: pointer">
              <img class="set" src="@/assets/icon/set.png" />
              <span style="color: #1890ff">列设置</span>
            </div>
          </el-popover>
        </div>
        <div>
          <el-dropdown @command="handleMenu">
            <el-button type="default"
              >更多操作<i class="el-icon-arrow-down el-icon--right"></i
            ></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="1" v-role="'RB-BR-QDDC'"
                >数电开票导出</el-dropdown-item
              >
              <el-dropdown-item :command="2" v-role="'RB-BR-QDDR'"
                >数电发票导入</el-dropdown-item
              >
              <el-dropdown-item :command="3" v-role="'RB-BR-TBFJ'"
                >同步发票附件</el-dropdown-item
              >
              <el-dropdown-item :command="4" :disabled="selections.length < 1"
                >修改税编版本</el-dropdown-item
              >
              <el-dropdown-item :command="5" :disabled="selections.length != 1"
                >修改发票状态</el-dropdown-item
              >
              <el-dropdown-item :command="6" :disabled="selections.length < 1"
                >批量打印发票</el-dropdown-item
              >
              <el-dropdown-item :command="7" :disabled="selections.length < 1"
                >批量交付发票</el-dropdown-item
              >
              <el-dropdown-item
                :command="8"
                :disabled="selections.length < 1"
                v-role="'RB-BR-TBZT'"
                >同步数电状态</el-dropdown-item
              >
              <el-dropdown-item :command="9" :disabled="exportDisabled"
                >批量导出发票</el-dropdown-item
              >
              <el-dropdown-item :command="10" :disabled="selections.length < 1"
                >数电发票重开</el-dropdown-item
              >
              <el-dropdown-item :command="11" v-role="'RB-BR-LXXG'"
                >修改发票类型</el-dropdown-item
              >
              <el-dropdown-item :command="12" v-role="'RB-BR-ZZXG'"
                >修改发票组织</el-dropdown-item
              >
              <el-dropdown-item
                :command="13"
                v-role="'RB-BR-LXXG'"
                :disabled="selections.length != 1"
                >修改开票抬头</el-dropdown-item
              >
              <el-dropdown-item
                :command="14"
                v-role="'RB-BR-PLSC'"
                :disabled="selections.length == 0"
                >批量删除发票</el-dropdown-item
              >
              <el-dropdown-item
                :command="15"
                v-role="'RB-BR-PLXZ'"
                :disabled="selections.length == 0"
                >批量下载附件</el-dropdown-item
              >
              <el-dropdown-item
                :command="16"
                v-role="'RB-BR-TBXF'"
                :disabled="selections.length == 0"
                >同步销方信息</el-dropdown-item
              >
              <el-dropdown-item :command="17">批量修改标签</el-dropdown-item>
              <el-dropdown-item :command="18">修改税率说明</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <span>&nbsp;&nbsp;&nbsp;</span>
          <el-dropdown @command="handleElectronPrint">
            <el-button type="primary"
              >电票打印<i class="el-icon-arrow-down el-icon--right"></i
            ></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1">不含清单</el-dropdown-item>
              <el-dropdown-item command="2">包含清单</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <span>&nbsp;&nbsp;&nbsp;</span>
          <el-dropdown @command="handlePrintActions">
            <el-button type="default"
              >打印设置<i class="el-icon-arrow-down el-icon--right"></i
            ></el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="Invoice">发票设置</el-dropdown-item>
              <el-dropdown-item command="Details">清单设置</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button
            type="primary"
            @click="handleBatchPrint"
            :disabled="selections.length < 1"
            style="margin-left: 10px"
            >批量打印</el-button
          >
          <el-button
            type="primary"
            @click="handleBatchDeliver"
            :disabled="selections.length < 1"
            >批量交付</el-button
          >
          <el-button
            type="primary"
            v-role="'RB-BR-PLKP'"
            @click="handleBatchBill"
            :disabled="selections.length < 1"
            >批量开票</el-button
          >
        </div>
      </div>
      <!--票据列表-->
      <div class="content-table">
        <el-table
          border
          stripe
          :data="tableData"
          v-loading="dataLoading"
          :header-cell-style="handleHeaderCellStyle"
          style="width: 100%"
          @cell-click="handleCopyMessage"
          ref="tableDataRef"
          @selection-change="handleSelectionChange"
          @row-click="handleOpenDetails"
        >
          <el-table-column type="selection" fixed="left" prop="id" />
          <el-table-column
            type="index"
            label="序号"
            fixed="left"
            width="60"
            class-name="dl-pointer"
          />
          <el-table-column
            v-if="colData[0] && colData[0].isTrue"
            show-overflow-tooltip
            prop="sheetCode"
            width="200"
          >
            <template slot="header" slot-scope="scope">
              <p-order-column
                ref="sheetCode"
                label="订单编号"
                :prop="scope.column"
                @sort="handleSort"
              ></p-order-column>
            </template>
          </el-table-column>
          <el-table-column
            v-if="colData[31] && colData[31].isTrue"
            prop="billSerialNo"
            label="流水编号"
            width="180"
          />
          <el-table-column
            v-if="colData[30] && colData[30].isTrue"
            prop="businessCode"
            label="业务单号"
            width="200"
          />
          <el-table-column
            v-if="colData[26] && colData[26].isTrue"
            prop="sellerName"
            label="销方名称"
            width="100"
          />
          <el-table-column
            v-if="colData[1] && colData[1].isTrue"
            prop="buyerName"
            show-overflow-tooltip
            label="购方名称"
            width="220"
          />
          <el-table-column
            v-if="colData[2] && colData[2].isTrue"
            prop="buyerTaxNo"
            label="购方税号"
            width="170"
          />
          <el-table-column
            v-if="colData[3] && colData[3].isTrue"
            prop="invoiceCode"
            label="发票代码"
            width="110"
          >
          </el-table-column>
          <el-table-column
            v-if="colData[4] && colData[4].isTrue"
            prop="invoiceNo"
            width="200"
          >
            <template slot="header" slot-scope="scope">
              <p-order-column
                ref="invoiceNo"
                label="发票号码"
                :prop="scope.column"
                @sort="handleSort"
              ></p-order-column>
            </template>
          </el-table-column>
          <!-- <el-table-column
            v-if="colData[24] && colData[24].isTrue"
            prop="eInvoiceNo"
            label="数电票号"
            width="150"
            :formatter="handleTableValueFormat"
          /> -->
          <el-table-column
            v-if="colData[5] && colData[5].isTrue"
            prop="invoiceTime"
            width="180"
            :formatter="handleTableValueFormat"
          >
            <template slot="header" slot-scope="scope">
              <p-order-column
                ref="invoiceTime"
                label="开票日期"
                :prop="scope.column"
                @sort="handleSort"
              ></p-order-column>
            </template>
          </el-table-column>
          <el-table-column
            v-if="colData[6] && colData[6].isTrue"
            prop="amount"
            label="发票金额"
            width="100"
            align="left"
            :formatter="handleTableValueFormat"
          />
          <el-table-column
            v-if="colData[7] && colData[7].isTrue"
            prop="taxAmount"
            label="发票税额"
            width="100"
            align="left"
            :formatter="handleTableValueFormat"
          />
          <el-table-column
            v-if="colData[8] && colData[8].isTrue"
            prop="sumAmount"
            label="价税合计"
            width="100"
            align="left"
            :formatter="handleTableValueFormat"
          />
          <el-table-column
            v-if="colData[9] && colData[9].isTrue"
            prop="taxRate"
            label="主税率值"
            width="80"
            :formatter="handleTableValueFormat"
          />
          <el-table-column
            v-if="colData[10] && colData[10].isTrue"
            prop="billingType"
            label="发票类型"
            width="110"
            :formatter="handleTableValueFormat"
          />
          <el-table-column
            v-if="colData[11] && colData[11].isTrue"
            prop="invoiceStatus"
            label="发票状态"
            width="100"
          >
            <template slot-scope="scope">
              <div
                :class="handleBillingTypeColor(scope.row.invoiceStatus)"
                class="invoiceStatus"
              >
                {{
                  handleValueToLabel("InvoiceStatus", scope.row.invoiceStatus)
                }}
                <img
                  v-if="scope.row.invoiceStatus == 'KJSB'"
                  class="tips"
                  src="@/assets/icon/warntips.png"
                />
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="colData[12] && colData[12].isTrue"
            prop="failedMessage"
            show-overflow-tooltip
            label="失败原因"
          />
          <el-table-column
            v-if="colData[21] && colData[21].isTrue"
            prop="remark"
            show-overflow-tooltip
            label="发票备注"
          />
          <el-table-column
            v-if="colData[13] && colData[13].isTrue"
            prop="billingFrom"
            label="业务类型"
            width="110"
            :formatter="handleTableValueFormat"
          />
          <el-table-column
            v-if="colData[20] && colData[20].isTrue"
            prop="specialInvoiceType"
            label="订单来源"
            width="110"
            :formatter="handleTableValueFormat"
          />
          <el-table-column
            v-if="colData[14] && colData[14].isTrue"
            prop="invoicePrintStatus"
            label="打印状态"
            width="100"
            :formatter="handleTableValueFormat"
          />
          <el-table-column
            v-if="colData[15] && colData[15].isTrue"
            label="税控设备"
            width="120"
            align="left"
          >
            <template slot-scope="scope">
              <span>分机编号：{{ scope.row.equipmentExtensionNo }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="colData[16] && colData[16].isTrue"
            prop="payer"
            label="开票人员"
            width="110"
            :formatter="handleTableValueFormat"
          />
          <el-table-column
            v-if="colData[17] && colData[17].isTrue"
            prop="deliverStatus"
            label="交付状态"
            width="100"
            :formatter="handleTableValueFormat"
          />
          <el-table-column
            v-if="colData[18] && colData[18].isTrue"
            label="存在附件"
            width="120"
            align="left"
          >
            <template slot-scope="scope">
              <span>{{ isExistInvoiceFile(scope.row) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            v-if="colData[27] && colData[27].isTrue"
            prop="deptCode"
            :formatter="handleTableValueFormat"
            label="所属部门"
            width="100"
          />
          <el-table-column
            v-if="colData[28] && colData[28].isTrue"
            prop="tagsArr"
            label="发票标签"
            width="140"
          >
            <template slot-scope="scope">
              <el-tag
                v-for="(item, index) in scope.row.tagsArr"
                style="margin-left: 12px"
                :key="index"
                type="info"
                >{{ item }}</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            v-if="colData[29] && colData[29].isTrue"
            prop="creatorName"
            label="操作人员"
            width="100"
          />
          <el-table-column
            v-if="colData[22] && colData[22].isTrue"
            prop="billingFlag"
            :formatter="handleTableValueFormat"
            label="开票标识"
            width="100"
          />
          <el-table-column
            v-if="colData[23] && colData[23].isTrue"
            prop="writeBackStatus"
            :formatter="handleTableValueFormat"
            label="回写状态"
            width="100"
          />
          <el-table-column
            v-if="colData[32] && colData[32].isTrue"
            prop="writeBackMessage"
            :formatter="handleTableValueFormat"
            show-overflow-tooltip
            label="失败原因"
            width="100"
          />
          <el-table-column
            v-if="colData[25] && colData[25].isTrue"
            :formatter="handleTableValueFormat"
            label="已经重开"
            prop="reissue"
            width="100"
          />
          <el-table-column
            v-if="colData[26] && colData[26].isTrue"
            label="销方组织"
            prop="sellerName"
            width="100"
          />
          <el-table-column
            v-if="colData[19] && colData[19].isTrue"
            prop="operate"
            label="操作"
            width="120"
            fixed="right"
          >
            <template slot-scope="scope">
              <div
                v-if="scope.row.invoiceStatus === 'KJSB'"
                class="operate-button"
              >
                <el-button type="text" @click="handleDetailInvoice(scope.row)"
                  >详情</el-button
                >
                <span>&nbsp;&nbsp;</span>
                <el-dropdown
                  @command="handleTableItemMenu($event, scope.row)"
                  size="mini"
                >
                  <span class="el-dropdown-link"
                    >更多<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="10">开具</el-dropdown-item>
                    <el-dropdown-item :command="11">删除</el-dropdown-item>
                    <el-dropdown-item :command="1">复制</el-dropdown-item>
                    <el-dropdown-item
                      :command="7"
                      v-if="
                        scope.row.billingChannel == 'NN-JG-JSP' &&
                        scope.row.billSerialNo
                      "
                      >同步</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div class="operate-button" v-else>
                <el-button type="text" @click="handleDetailInvoice(scope.row)"
                  >详情</el-button
                >
                <span>&nbsp;&nbsp;</span>
                <el-dropdown
                  @command="handleTableItemMenu($event, scope.row)"
                  size="mini"
                >
                  <span class="el-dropdown-link"
                    >更多<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>

                  <el-dropdown-menu class="even_style" slot="dropdown">
                    <el-dropdown-item :command="1">复制</el-dropdown-item>
                    <el-dropdown-item
                      :command="2"
                      :disabled="handleInvoiceButtonDisabled(1, scope.row)"
                      >冲红</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="3"
                      v-if="
                        scope.row.billingType != 'Dzpp' &&
                        scope.row.billingType != 'DZZP' &&
                        scope.row.billingType != 'SGDP'
                      "
                      :disabled="handleInvoiceButtonDisabled(2, scope.row)"
                      >作废</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="4"
                      v-if="
                        scope.row.billingType === 'QDPP' ||
                        scope.row.billingType === 'QDZP' ||
                        scope.row.billingType === 'Dzpp' ||
                        scope.row.billingType === 'DZZP' ||
                        scope.row.billingType == 'SGDP'
                      "
                      :disabled="handleInvoiceButtonDisabled(4, scope.row)"
                      >下载</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="5"
                      v-if="
                        scope.row.billingType === 'QDPP' ||
                        scope.row.billingType === 'QDZP' ||
                        scope.row.billingType === 'Dzpp' ||
                        scope.row.billingType === 'DZZP' ||
                        scope.row.billingType == 'SGDP'
                      "
                      :disabled="handleInvoiceButtonDisabled(3, scope.row)"
                      >交付</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="6"
                      v-else
                      :disabled="handleInvoiceButtonDisabled(5, scope.row)"
                      >打印</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="7"
                      v-if="
                        scope.row.billingChannel == 'NN-JG-JSP' &&
                        scope.row.billSerialNo &&
                        ['YKJ', 'KJZ', 'ZFZ'].indexOf(scope.row.invoiceStatus) >
                          -1
                      "
                      @click="handleSyncData(scope.row)"
                      >同步</el-dropdown-item
                    >
                    <el-dropdown-item :command="8" v-role="'RB-BR-SCFJ'"
                      >上传发票附件</el-dropdown-item
                    >
                    <el-dropdown-item :command="9" v-role="'RB-BR-XZFJ'"
                      >下载发票附件</el-dropdown-item
                    >
                    <el-dropdown-item :command="12" v-role="'RB-BR-XZFJ'"
                      >编辑标签</el-dropdown-item
                    >
                    <el-dropdown-item
                      :command="13"
                      v-if="
                        ['YKJ', 'YCH'].indexOf(scope.row.invoiceStatus) > -1
                      "
                      >查看发票二维码</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="table-statistics">
          <div class="statistics-left">
            <div>
              已选：<span style="color: red"> {{ selections.length }} </span>条
            </div>
            <div>金额：￥{{ selectedAmount }}</div>
            <div>税额：￥{{ selectedTaxAmount }}</div>
            <div>价税合计：￥{{ selectedSumAmount }}</div>
          </div>
          <div class="statistics-right">
            <div>总数量：{{ summary.total }}条</div>
            <div>总金额：￥{{ summary.amount }}</div>
            <div>总税额：￥{{ summary.taxAmount }}</div>
            <div>总价税合计：￥{{ summary.sumAmount }}</div>
          </div>
        </div>
      </div>
      <!-- 分页组件 -->
      <div class="content-page">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryForm.page"
          :page-sizes="pageSizes"
          :page-size="queryForm.size"
          layout="total, sizes, prev, pager, next"
          :total="summary.total"
        ></el-pagination>
      </div>
    </div>
    <!-- 详情弹窗 -->
    <invoice-open-detail
      :show.sync="drawer"
      :invoice="invoice"
      @openDetail="openDetail"
      @refreshDetail="refreshDetail"
    />
    <!-- 冲红弹窗 -->
    <invoice-tored
      ref="invoiceTored"
      :invoice="checkData"
      @success="handleQuery"
    />
    <!-- 作废弹窗 -->
    <invoice-revoke
      ref="invoiceRevoke"
      :invoice="checkData"
      @success="handleQuery"
    />
    <!-- 发票开具 -->
    <invoice-bill
      v-if="issueVisible"
      :show.sync="issueVisible"
      :recordBillingBlush="recordBillingBlush"
      :failRedInvoice="failRedInvoice"
      :invoice="issueData"
      @invoice-close="handleQuery"
      @billing-invoice="handleBillingInvoice"
    ></invoice-bill>
    <!-- 导出弹窗 -->
    <el-dialog
      append-to-body
      title="提示"
      :visible.sync="exportToastVisible"
      width="500px"
      close="toastClosed"
    >
      <div class="export-select" style="margin-top: -40px">
        <span>导出文件格式</span>
        <el-select v-model="queryForm.exportType">
          <el-option value="excel" label="Excel文件" />
          <el-option value="txt" label="txt文件" />
        </el-select>
      </div>
      <span class="dialog-message" v-if="selections.length > 0"
        >您是否要导出已选中或查询出来的{{ selections.length }}张发票数据？</span
      >
      <span class="dialog-message" v-else
        >您是否要导出已选中或查询出来的{{ summary.total }}张发票数据？</span
      >
      <br />
      <span style="font-size: 14px; color: #d60303"
        >注：导出数据时，仅会导出列设置中已选中的字段。</span
      >
      <div slot="footer" class="dialog-footer">
        <el-button @click="exportToastVisible = false">取 消</el-button>
        <el-button type="primary" @click="exportExcel">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      append-to-body
      title="发票附件上传"
      :close-on-click-modal="false"
      :visible.sync="uploadFileVisible"
      width="500px"
      close="toastClosed"
    >
      <div class="export-select" style="padding-left: 60px; margin-top: -40px">
        <div class="upload-file">
          <label>XML格式:</label>
          <el-upload
            style="margin: 0 10px 0 20px"
            :accept="'.xml'"
            action="#"
            :multiple="false"
            :show-file-list="false"
            :on-change="(file) => uploadInvoiceFile(file, 'xml')"
          >
            <el-button type="primary" style="height: 34px">选择文件</el-button>
          </el-upload>
          <div class="invoice-file-name">{{ invoiceFiles.xmlFileName }}</div>
        </div>
        <div class="upload-file">
          <label>PDF格式:</label>
          <el-upload
            style="margin: 0 10px 0 20px"
            :accept="'.pdf'"
            action="#"
            :multiple="false"
            :show-file-list="false"
            :on-change="(file) => uploadInvoiceFile(file, 'pdf')"
          >
            <el-button type="primary" style="height: 34px">选择文件</el-button>
          </el-upload>
          <div class="invoice-file-name">{{ invoiceFiles.pdfFileName }}</div>
        </div>
        <div class="upload-file">
          <label>OFD格式:</label>
          <el-upload
            style="margin: 0 10px 0 20px"
            :accept="'.ofd'"
            action="#"
            :multiple="false"
            :show-file-list="false"
            :on-change="(file) => uploadInvoiceFile(file, 'ofd')"
          >
            <el-button type="primary" style="height: 34px">选择文件</el-button>
          </el-upload>
          <div class="invoice-file-name">{{ invoiceFiles.ofdFileName }}</div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="uploadFileVisibleClose">取 消</el-button>
        <el-button
          type="primary"
          :disabled="
            invoiceFiles.ofdFile == null &&
            invoiceFiles.xmlFile == null &&
            invoiceFiles.pdfFile == null
          "
          @click="uploadInvoiceFileSubmit"
          >确 定
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      class="file-dialog"
      append-to-body
      title="发票附件下载"
      :visible.sync="downloadFileVisible"
      width="500px"
      close="toastClosed"
    >
      <div class="export-select" style="margin-top: -40px">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 30px;
            padding-right: 30px;
          "
        >
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <img src="@/assets/icon/PDF.png" />
            <el-button
              type="text"
              style="padding: 0"
              @click="handleInvoiceFileDownLoad(invoiceFileId.pdfFileId, 'PDF')"
              >PDF文件</el-button
            >
          </div>
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <img src="@/assets/icon/OFD.png" />
            <el-button
              type="text"
              style="padding: 0"
              @click="handleInvoiceFileDownLoad(invoiceFileId.ofdFileId, 'OFD')"
              >OFD文件</el-button
            >
          </div>
          <div
            style="display: flex; justify-content: center; align-items: center"
          >
            <img src="@/assets/icon/xml.png" />
            <el-button
              type="text"
              style="padding: 0"
              @click="handleInvoiceFileDownLoad(invoiceFileId.xmlFileId, 'XML')"
              >XML文件</el-button
            >
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer"></div>
    </el-dialog>
    <!-- 批量开票 -->
    <el-dialog
      append-to-body
      :visible.sync="billBatchVisible"
      title="批量开票信息核对"
      width="358px"
      :show-close="false"
    >
      <invoice-bill-batch
        v-if="billBatchVisible"
        :invoices="this.sendInvoices"
        @close="billBatchVisible = false"
        @success="handleBatchBillSuccess"
      ></invoice-bill-batch>
    </el-dialog>
    <!-- 发票明细 -->
    <invoiceDetail
      ref="invoiceDetail"
      :invoiceStatus="rowInvoiceStatus"
      :invoiceId="invoiceId"
    ></invoiceDetail>
    <!-- 复制弹窗 -->
    <invoice-copy
      ref="invoiceCopy"
      :invoice="checkData"
      @success="handelCopyInvoiceSuccess"
    />
    <!-- 发票打印 -->
    <invoice-print ref="invoicePrint" :invoices="printInvoices" />
    <!-- 发票交付 -->
    <invoice-send ref="invoiceSend" :invoices="sendInvoices" />
    <!-- 发票删除 -->
    <invoice-delete
      ref="invoiceDelete"
      :invoices="deleteInvoices"
      @success="handleQuery"
    />
    <!-- 更新抬头 -->
    <update-buyer
      :show.sync="invoiceTitleVisible"
      :invoice-title="invoiceTitle"
      @success="handleQuery"
    />
    <!-- 修改发票状态 -->
    <invoice-update-status
      ref="invoiceUpdateStatus"
      :updateInvoice="updateInvoice"
      @resetBtn="handleUpdateReset"
      @success="handleUpdateQuery"
    />
    <invoice-item-update-version
      ref="invoiceItemUpdateVersion"
      :invoices="iuvInvoices"
    />
    <div id="printTable">
      <iframe
        v-if="printPdfData"
        :src="printPdfData"
        width="100%"
        height="100%"
        style="display: none"
      ></iframe>
    </div>
    <el-dialog
      title="全电开票结果导入"
      :visible.sync="centerDialogVisible"
      width="30%"
    >
      <span style="color: red; font-size: 17px; padding: 15px"
        >发票数据及状态是通过“发票流水号”进行关联更新的，请仔细核对后上传。</span
      >
      <el-form
        ref="form"
        :model="importForm"
        :rules="importRules"
        label-width="140px"
        style="text-align: left"
      >
        <el-form-item
          style="padding: 15px"
          label="开票结果导入："
          prop="fileName"
        >
          <el-input placeholder="请选择文件" v-model="importForm.fileName">
            <el-button
              slot="append"
              icon="el-icon-folder-opened"
              @click="openFile"
            ></el-button>
          </el-input>
          <input
            type="file"
            name="filename"
            id="open"
            style="display: none"
            @change="changeFile"
          />
          <el-link style="font-size: 15px" type="primary" @click="previewImg"
            >文件下载路径指引
          </el-link>
        </el-form-item>
        <el-form-item style="padding: 15px" label="开票时间：" prop="date">
          <el-date-picker
            v-model="importForm.date"
            type="datetime"
            format="yyyy-MM-dd  HH:mm:ss"
            placeholder="选择日期时间："
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="rpaImport()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 修改发票类型弹窗   -->
    <el-dialog
      append-to-body
      title="修改发票类型"
      width="26%"
      :visible.sync="updateBillingTypeVisible"
    >
      <div class="wait-invoice" style="margin-bottom: 30px">
        <span class="invoice-type"> 发票类型 </span>
        <el-select
          v-model="editBillingTypeParam.billingType"
          placeholder="请选择"
        >
          <el-option
            v-for="item in invoiceType"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="dialog-footer" slot="footer" style="margin-right: 5px">
        <el-button type="default" @click="updateBillingTypeVisible = false"
          >取消</el-button
        >
        <el-button type="primary" @click="confirmUpdateBillingType"
          >确认</el-button
        >
      </div>
    </el-dialog>
    <!-- 修改发票类型弹窗   -->
    <el-dialog
      append-to-body
      title="修改发票类型"
      width="26%"
      :visible.sync="updateInvoiceOrgVisible"
    >
      <div class="wait-invoice" style="margin-bottom: 30px">
        <span class="invoice-type"> 所属组织 </span>
        <organization-select
          @handleSelectAction="handleCurrentOrganizations"
          :is-add-all="false"
          :model="editInvoiceOrgParam"
          allLabel="请选择组织"
        >
        </organization-select>
      </div>
      <div class="dialog-footer" slot="footer" style="margin-right: 5px">
        <el-button type="default" @click="updateInvoiceOrgVisible = false"
          >取消</el-button
        >
        <el-button type="primary" @click="confirmUpdateInvoiceOrg"
          >确认</el-button
        >
      </div>
    </el-dialog>

    <!-- 修改税率说明 -->
    <edit-tax-rate-explain
      ref="editTaxRateExplainRef"
      @success="handleQuery"
    ></edit-tax-rate-explain>

    <!-- 批量下载 -->
    <el-dialog
      title="批量下载"
      :visible.sync="downloadInBatchesVisible"
      width="35%"
    >
      <div class="alteration_organization" style="margin-top: -40px">
        <el-form :model="form" style="margin: 10px 0 110px 40px">
          <div class="alteration_organization">
            <el-form-item style="text-align: left" label="选择下载类型"
              ><br />
              <el-radio-group
                style="width: 100%"
                v-model="groupByRule"
                size="small"
              >
                <div style="margin: 15px 0 15px 0">
                  <el-row :gutter="20">
                    <el-col :span="8">
                      <el-radio style="width: 100%" label="noRule" border
                        >不归类</el-radio
                      ></el-col
                    >
                    <el-col :span="8"
                      ><el-radio style="width: 100%" label="sellerName" border
                        >按组织归类</el-radio
                      >
                    </el-col>
                    <el-col :span="8">
                      <el-radio style="width: 100%" label="invoiceType" border
                        >按发票类型归类</el-radio
                      ></el-col
                    >
                  </el-row>
                </div>
                <el-row :gutter="20">
                  <el-col :span="8">
                    <el-radio style="width: 100%" label="taxRate" border
                      >按税率归类</el-radio
                    >
                  </el-col>
                </el-row>
              </el-radio-group>
            </el-form-item>
            <el-form-item
              style="text-align: left; margin-top: 130px"
              label="选择下载文件类型"
              ><br />
              <el-select
                multiple
                v-model="downFileTypeSet"
                collapse-tags
                placeholder="请选择"
              >
                <el-option label="PDF" value="PDF"> </el-option>
                <el-option label="OFD" value="OFD"> </el-option>
                <el-option label="XML" value="XML"> </el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="downloadInBatchesVisible = false">取 消</el-button>
        <el-button type="primary" @click="handlerDownloadInBatches"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 批量删除 -->
    <el-dialog
      title="批量删除"
      :visible.sync="deleteInBatchesVisible"
      width="35%"
    >
      <div class="alteration_organization" style="margin-top: -40px">
        是否删除所选的{{ selections.length }}张发票？点击【确认】删除成功
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteInBatchesVisible = false">取 消</el-button>
        <el-button type="primary" @click="handlerDeleteInBatches"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="发票二维码"
      :visible.sync="invoiceImageQRVisible"
      width="25%"
      @close="invoiceImageQRClose"
    >
      <div class="alteration_organization" style="margin-top: -70px">
        <el-image
          style="width: 250px; height: 250px"
          v-loading="invoiceImageLoading"
          :src="previewImgQR"
        />
      </div>
      <span slot="footer" class="dialog-footer"> </span>
    </el-dialog>
    <el-image
      ref="preview"
      style="width: 100px; height: 100px; display: none"
      :preview-src-list="invoiceImages"
    >
    </el-image>

    <div class="edit_label">
      <el-dialog
        title="编辑标签"
        :close-on-click-modal="false"
        :visible.sync="dialogVisibleEditLabel"
        width="30%"
      >
        <div>
          <span for="">已添加的标签（{{ tagList.length }}/10）</span>
          <div style="margin-top: 20px">
            <el-tag
              style="margin-bottom: 20px"
              v-for="tag in tagList"
              @close="handleClose(tag)"
              :key="tag.name"
              closable
              :type="tag.type"
            >
              {{ tag.name }}
            </el-tag>
          </div>
        </div>
        <div
          style="border-bottom: 1px solid #f4f4f5; margin-bottom: 20px"
        ></div>
        <div>
          <span for="">可添加的标签</span>
          <div class="addible" style="margin: 20px 0">
            <el-tag
              style="margin-bottom: 20px"
              :key="tag.code"
              v-for="tag in dynamicTags"
              :disable-transitions="false"
              @click="handleAdd(tag)"
            >
              {{ tag.name
              }}<i class="el-icon-plus" style="margin-left: 10px"></i
            ></el-tag>

            <el-input
              class="input-new-tag"
              style="margin-bottom: 20px"
              v-model="inputValue"
              ref="saveTagInput"
              size="small"
              @blur="handleInputConfirm"
              @keyup.enter.native="handleInputConfirm"
              v-if="inputVisible"
            >
            </el-input>
            <el-button
              v-else
              class="button-new-tag"
              size="small"
              @click="showInput"
              >新增标签 +
            </el-button>
          </div>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleEditLabel = false">取 消</el-button>
          <el-button type="primary" @click="_updateLabel">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {
  billList,
  billSummary,
  downloadInvoice,
  downloadInvoiceFile,
  exportAlreadyInvoices,
  getQueryIds,
  handleSyncToRedInvoiceStatus,
  importResultExport,
  querySingleInvoice,
  rebillInvoice,
  reissue,
  rpaExport,
  rpaImport,
  selectInvoice,
  syncInvoice,
  uploadInvoiceOriginFile,
  updateBillingType,
  updateInvoiceOrg,
  systemLabelPage,
  updateLabel,
  invoiceDownload,
  updateInvoiceSellerInfo,
  invoicePreviewImage,
  selectInvoiceDetail,
} from "@/service/sal/invoice";
import { genCode, labelAdd } from "@/service/system/label";
import { ReqOrganizationTagsTree } from "@/service/organization";
import { listEquipmentByOrg } from "@/service/equipment";
import printJs from "print-js";
import invoiceDetail from "./components/invoiceDetail.vue";
import POrderColumn from "@/components/pdt/POrderColumn";
import {
  changeMethods,
  commonsMethods,
  dateOption,
  tableStyle,
} from "@/util/mixins";
import {
  DELIVER_STATUS,
  INVOICE_STATUS,
  OPEN_INVOICE_TYPES,
  ORIGINAL_ORDER_BILLING_TYPES,
  RETAIL_ALREADY_LINE,
} from "@/content/invoice.js";
import InvoiceOpenDetail from "@/components/invoice/InvoiceOpenDetail";
import InvoiceBill from "@/views/invoice-open/components/invoice-bill";
import InvoiceSend from "@/views/invoice-open/components/invoice-send";
import InvoiceCopy from "@/views/invoice-open/components/invoice-copy";
import UpdateBuyer from "@/views/invoice-open/components/update-buyer";
import InvoicePrint from "@/views/invoice-open/components/invoice-print";
import InvoiceTored from "@/views/invoice-open/components/invoice-tored";
import InvoiceRevoke from "@/views/invoice-open/components/invoice-revoke";
import InvoiceDelete from "@/views/invoice-open/components/invoice-delete";
import invoiceUpdateStatus from "@/views/invoice-open/components/invoice-update-status";
import InvoiceItemUpdateVersion from "@/views/invoice-open/components/invoice-item-update-version";
import InvoiceBillBatch from "./components/InvoiceBillBatch";
import OrganizationSelect from "@/components/widgets/OrganizationSelect";
import EditTaxRateExplain from "./components/EditTaxRateExplain.vue";
import { fmtCurrency } from "@/assets/js/format-util.js";

export default {
  name: "RetailInvoiceAlready",
  mixins: [tableStyle, dateOption, changeMethods, commonsMethods],
  filters: {
    fmtEqpName(row) {
      if (row.extensionNo == undefined) return "全部";
      return "分机编号：" + row.extensionNo;
    },
  },
  props: {
    model: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    invoiceDetail,
    InvoiceBill,
    InvoiceSend,
    InvoiceCopy,
    UpdateBuyer,
    InvoicePrint,
    InvoiceTored,
    InvoiceRevoke,
    InvoiceDelete,
    InvoiceOpenDetail,
    POrderColumn,
    invoiceUpdateStatus,
    InvoiceItemUpdateVersion,
    InvoiceBillBatch,
    OrganizationSelect,
    EditTaxRateExplain,
  },
  data() {
    return {
      invoiceTitle: undefined,
      invoiceTitleVisible: false,
      dynamicTags: [],
      inputVisible: false,
      inputValue: "",
      tagList: [],
      addTagData: {},
      lineData: {},
      invoiceIdArrays: [],
      dialogVisibleEditLabel: false,
      RedInvoice: false,
      KPJL: "",
      recordBillingBlush: "", //手工开票冲红走原逻辑
      failRedInvoice: false, //开票失败的红票
      invoiceImages: [require("@/assets/img/guide/import-guide.png")],
      importForm: {
        fileName: "",
        date: "",
        file: {},
      },
      importRules: {
        fileName: [
          { required: true, message: "请选择导入文件", trigger: "blur" },
        ],
        date: [{ required: true, message: "请选择导入时间", trigger: "blur" }],
      },
      centerDialogVisible: false,
      src: "",
      invoiceId: 0,
      rowInvoiceStatus: "",
      numPages: 0,
      page: 1,
      currentPage: 0,
      reOpenStatusData: [
        { value: "", label: "全部" },
        { value: "Y", label: "是" },
        { value: "N", label: "否" },
      ],
      businessPractice: [],
      departmentList: [],
      departmentListS: [],
      writeBackStateList: [
        { value: "", label: "全部" },
        { value: "INIT", label: "初始状态" },
        { value: "HXCG", label: "回写成功" },
        { value: "HXSB", label: "回写失败  " },
      ],
      makeOutAnInvoiceRecognitionList: [
        { value: "", label: "全部" },
        { value: "Lp", label: "蓝票" },
        { value: "Hp", label: "红票" },
      ],
      colData: RETAIL_ALREADY_LINE,
      colOptions: [],
      selections: [], // 勾选项
      colSelect: RETAIL_ALREADY_LINE.map((i) => i.title),
      selectExpended: false,
      isIndeterminate: true,
      checkAll: false,
      tags: "",
      queryForm: {
        tags: "",
        id: undefined,
        ids: [],
        orgIds: [],
        billSerialNo: undefined,
        deptCode: undefined,
        buyerName: undefined,
        businessCode: undefined,
        invoiceNo: undefined,
        invoiceStatuses: [],
        equipmentExtensionNos: [],
        exportType: "excel",
        colOptions: [],
        sumAmount: undefined,
        tagCondition: "OR",
        querySource: "READY",
        page: 1,
        size: 15,
        creatorName: undefined,
      },
      editBillingTypeParam: {
        ids: [],
        billingType: undefined,
      },
      editInvoiceOrgParam: {
        ids: [],
        orgId: localStorage.getItem("orgId")
          ? Number(localStorage.getItem("orgId"))
          : "",
      },
      ids: [],
      dataTotal: 0,
      eqpExtensionNo: undefined,
      billedRange: [],
      invoiceType: OPEN_INVOICE_TYPES,
      invoiceStatus: INVOICE_STATUS,
      deliverStatus: DELIVER_STATUS,
      isExistFile: [
        {
          value: "",
          label: "全部",
        },
        {
          value: "Y",
          label: "是",
        },
        {
          value: "N",
          label: "否",
        },
      ],
      selectStatus: true,
      exportToastVisible: false,
      invoiceStatusVisible: false,
      deleteConfirmVisible: false,
      //表格数据区
      tableData: [],
      pageSizes: [15, 30, 50, 100],
      //详情数据区
      drawer: false,
      invoice: {},
      //冲红数据区
      invoiceRedVisible: false,
      invoiceRedData: {
        invoiceCode: "",
        invoiceCodeNew: "",
        invoiceNo: "",
        invoiceNoNew: "",
        billingType: "",
        amount: "",
        redNoticeNo: "",
        receiveEmail: "",
      },
      //作废数据区
      obsolete: false,
      invoiceCheckVisible: false,
      checkData: {
        id: "",
        invoiceCode: "",
        invoiceNo: "",
        billingType: "",
        amount: "",
        isDeliver: false,
        receiveEmail: "",
      },
      //开具数据区
      issueVisible: false,
      copyVisible: false,
      issueData: {},
      issueDetailVisible: false,
      forceUnlock: false,
      forceUnlockData: {},
      dataLoading: false,
      updateDisabled: false,
      exportDisabled: false,
      // 发票删除数据区
      deleteVisible: false,
      deleteTitle: "是否删除当前信息",
      deleteInvoices: [],
      /* 新增开票设备 */
      equipments: [],
      requestPrintFlag: true,
      orderSet: [], // 排序条件集合
      batchType: "",
      batchVisible: false,
      confirmLoading: false,
      // 发票打印
      printInvoices: [],
      // 发票交付
      sendInvoices: [],
      // 修改发票
      updateInvoice: {},
      taxRates: [],
      summary: {
        total: 0,
        amount: 0,
        taxAmount: 0,
        sumAmount: 0,
      },
      iuvInvoices: [],
      printPdfData: "",
      uploadFileVisible: false,
      invoiceFiles: {
        xmlFile: null,
        xmlFileName: "未选择任何文件",
        ofdFile: null,
        ofdFileName: "未选择任何文件",
        pdfFile: null,
        pdfFileName: "未选择任何文件",
        id: undefined,
      },
      downloadFileVisible: false,
      invoiceFileId: {
        xmlFileId: null,
        pdfFileId: null,
        ofdFileId: null,
      },
      downloadInvoiceFileArg: {
        originalFileId: null,
        id: null,
        imageType: null,
      },
      billBatchVisible: false,
      updateBillingTypeVisible: false,
      updateInvoiceOrgVisible: false,
      downloadInBatchesVisible: false,
      deleteInBatchesVisible: false,
      invoiceImageQRVisible: false,
      previewImgQR: "",
      invoiceImageLoading: true,
      downFileTypeSet: [],
      groupByRule: "noRule",
      form: {
        orgId: "",
        name: "",
        region: "",
      },
    };
  },
  computed: {
    selectStatusClass: function () {
      return this.selectStatus ? "" : "row-first";
    },
    selectStatusMessage: function () {
      return this.selectStatus ? "展开" : "收起";
    },
    isShowPrint: function () {
      return this.pintSetUpForm.printWay == "standard" ? true : false;
    },
    selectedAmount() {
      let amount = this.selections
        .map((i) => i.amount)
        .reduce((num, sum) => Number(num) + Number(sum), 0);
      return amount.toFixed(2);
    },
    selectedTaxAmount() {
      let taxAmount = this.selections
        .map((i) => i.taxAmount)
        .reduce((num, sum) => Number(num) + Number(sum), 0);
      return taxAmount.toFixed(2);
    },
    selectedSumAmount() {
      let sumAmount = this.selections
        .map((i) => i.sumAmount)
        .reduce((num, sum) => Number(num) + Number(sum), 0);
      return sumAmount.toFixed(2);
    },
  },
  watch: {
    "$store.state.invoiceIssue.CurrentOrganization"(value) {
      this.queryForm.orgId = value;
      this.reqListEquipment();
      this.systemLabelPage();
      //this.ReqOrganizationTagsTree();
    },
    tableData(value) {
      if (value.length == 0) {
        this.exportDisabled = true;
      } else {
        this.exportDisabled = false;
      }
    },
    colOptions(valArr) {
      const arr = this.colSelect.filter((i) => valArr.indexOf(i) < 0); // 未选中
      this.colData.filter((i) => {
        if (arr.indexOf(i.title) != -1) {
          i.isTrue = false;
          this.$nextTick(() => {
            this.$refs.tableDataRef.doLayout();
          });
        } else {
          i.isTrue = true;
          this.$nextTick(() => {
            this.$refs.tableDataRef.doLayout();
          });
        }
      });
      localStorage.setItem("lineSet", JSON.stringify(this.colData));
    },
  },
  created() {
    this.getDefault();
    this.queryForm.orgId = this.$route.query.orgId;
    this.handleGetlineSet();
    Promise.all([this.reqListEquipment(), this.reqListDictionary()]).then(
      (results) => {
        this.handleQuery();
      }
    );
    this.systemLabelPage();
    this.ReqOrganizationTagsTree();
  },
  methods: {
    getDefault() {
      let setAll = localStorage.getItem("setOrgId");
      if (setAll == "2") {
        this.$emit("setOrgIdEmit", 0);
      } else {
        this.$emit("setOrgIdEmit", localStorage.getItem("orgId" || ""));
      }
    },
    handleAdd(tag) {
      if (this.tagList.length >= 10) {
        this.toast("一张发票最多可添加10个标签", "warning");
        return;
      }
      let item = { type: "info" };
      item.name = tag.name;
      item.code = tag.code;
      this.tagList.push(item);
      this.dynamicTags = this.dynamicTags.filter(
        (item) => item.code !== tag.code
      );
    },
    handleClose(tag) {
      this.tagList = this.tagList.filter((item) => item !== tag);
      this.dynamicTags.push(tag);
    },
    async _updateLabel() {
      this.tagList = this.tagList.map((item) => item.code);
      let res = await updateLabel({
        ids: this.invoiceIdArrays,
        tags: this.tagList.join(","),
      });
      if (res.success) {
        this.toast("编辑标签成功", "success");
      }
      this.dialogVisibleEditLabel = false;
      this.handleQuery();
    },
    //  添加标签
    async showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
      const { success, data } = await genCode();
      if (success) {
        this.addTagData = {
          name: "",
          code: data,
          type: "SALE",
          related: "",
        };
      }
    },
    async handleInputConfirm() {
      this.addTagData.name = this.inputValue;
      if (this.inputValue) {
        let res = await labelAdd(this.addTagData);
        if (res.success) {
          this.systemLabelPage();
        }
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    async ReqOrganizationTagsTree() {
      this.departmentList = [];
      let { success, data } = await ReqOrganizationTagsTree();
      if (success) {
        if (data.length !== 0) {
          this.departmentList = [...data];
          this.departmentListS = data;
        }
      }
    },
    async ReqInvoicePreviewImage(row) {
      if (row.invoiceStatus != "YKJ" && row.invoiceStatus != "YCH") {
        this.toast(
          "只允许发票状态为[已开具]或[已冲红]的发票查看二维码",
          "waring"
        );
        return;
      }
      this.invoiceImageLoading = true;
      this.invoiceImageQRVisible = true;
      let { success, data } = await invoicePreviewImage(row.id);
      if (success) {
        this.previewImgQR = "data:image/jpg;base64," + data;
      }
      this.invoiceImageLoading = false;
    },
    invoiceImageQRClose() {
      this.previewImgQR = "";
    },
    async systemLabelPage() {
      let res = await systemLabelPage({
        type: "SALE",
        page: "1",
        size: "1000",
      });
      if (res.success) {
        this.businessPractice = res.data.records;
        console.log(this.tagList);
        this.dynamicTags = res.data.records.filter((item) => {
          return !this.tagList.some((ele) => ele.name === item.name);
        });
      }
    },
    async invoiceReOpen() {
      let arr = this.selections.filter(
        (s) =>
          !(
            s.invoiceStatus === "YZF" ||
            s.invoiceStatus === "YCH" ||
            s.reissue !== "Y"
          )
      );
      if (arr.length > 0) {
        this.$confirm(
          "仅发票状态为已作废或已冲红且是否已重开为否的发票才可以进行发票重开",
          "提示",
          { type: "warning" }
        );
        return;
      }
      let ids = this.selections.map((i) => i.id);
      const { success } = await reissue(ids);
      if (success) {
        this.toast("操作成功", "success");
        this.handleQuery();
      }
    },
    // 发票明细
    handleOpenDetails(row, column) {
      if (["序号"].indexOf(column.label) == -1) {
        if ([undefined, "操作"].indexOf(column.label) == -1) {
          this.toast("点击“序号”列表区域，查看该票明细信息！", "info");
        }
        return;
      }
      // console.log(row, 'row');
      this.invoiceId = row.id;
      this.rowInvoiceStatus = row.invoiceStatus;
      // this.billingFrom = row.billingFrom;
      setTimeout(() => {
        this.$refs.invoiceDetail.open();
      }, 0);
    },
    openDetail(id) {
      this.invoiceId = id;
      this.drawer = false;
      // this.billingFrom = row.billingFrom;
      setTimeout(() => {
        this.$refs.invoiceDetail.open();
      }, 300);
    },
    // 表格-更多操作
    handleTableItemMenu(type, item) {
      // console.log(type, item);
      if (this.RedInvoice && type === 2) {
        type = 10;
        item.billingFlag = "Hp";
        this.recordBillingBlush = "blush";
      } else {
        this.recordBillingBlush = "";
        item.billingFlag = item.billingFlag;
      }
      // console.log(type, item);
      switch (type) {
        case 1:
          this.handelCopyInvoice(item);
          break;
        case 2:
          this.handleInvoiceTored(item);
          break;
        case 3:
          this.handleInvoiceRevoke(item);
          break;
        case 4:
          this.handleInvoiceDownLoad(item);
          break;
        case 5:
          this.handleDeliver(item);
          break;
        case 6:
          this.handlePrint(item);
          break;
        case 7:
          this.handleSyncData(item);
          break;
        case 8:
          this.uploadFile(item);
          break;
        case 9:
          this.downloadInvoiceFileDialog(item);
          break;
        case 10:
          this.handleInvoiceIssue(item, this.recordBillingBlush);
          break;
        case 11:
          this.handleInvoiceDelete(item);
          break;
        case 12:
          this.handelEditLabel(item);
          break;
        // case 12:
        //   this.handelCopyInvoice(item);
        //   break;
        case 13:
          this.ReqInvoicePreviewImage(item);
          break;
        default:
          break;
      }
    },
    handelEditLabel(item) {
      if (item == null) {
        item = { tags: "", id: 0 };
      }
      if (item.id > 0) {
        this.invoiceIdArrays = [item.id];
      } else {
        let arr = this.selections;
        if (arr.length == 0) {
          this.toast("请选择修改标签的发票数据", "waring");
          return;
        }
        let ids = this.selections.map((i) => i.id);
        this.invoiceIdArrays = ids;
      }

      this.lineData = item;

      let added = item.tags.split(",");
      this.tagList = this.businessPractice.filter((item) => {
        return added.includes(item.code);
      });
      for (let i = 0; i < this.tagList.length; i++) {
        this.tagList[i].type = "info";
      }
      this.dynamicTags = this.businessPractice.filter((item) => {
        return !added.includes(item.code);
      });
      this.dialogVisibleEditLabel = true;
    },
    // 批量-更多操作
    handleMenu(type) {
      switch (type) {
        case 1:
          this.rpaExport();
          break;
        case 2:
        case 3:
          this.centerDialogVisible = true;
          break;
        case 4:
          this.handleUpdateVersion();
          break;
        case 5:
          this.updateInvoiceStatus();
          break;
        case 6:
          this.handleBatchPrint();
          break;
        case 7:
          this.handleBatchDeliver();
          break;
        case 8:
          this.handleSyncToRedStatus();
          break;
        case 9:
          this.handleExport();
          break;
        case 10:
          this.invoiceReOpen();
          break;
        case 11:
          this.handleBillingType();
          break;
        case 12:
          this.handleUpdateInvoiceOrg();
          break;
        case 13:
          this.handleUpdateInvoiceTitle();
          break;
        case 14:
          this.handleDeleteInvoiceBatch();
          break;
        case 15:
          this.handleDownFile();
          break;
        case 16:
          this.handleUpdateSellerInfo();
          break;
        case 17:
          this.handelEditLabel(null);
          break;
        case 18:
          this.handleEditTaxRateExplain();
          break;
        default:
          break;
      }
    },
    handleDeleteInvoiceBatch() {
      this.deleteInvoices = this.selections;
      let unYKJ = this.deleteInvoices.filter(
        (item) => item.invoiceStatus != "KJSB"
      );
      console.log(unYKJ);
      if (unYKJ.length > 0) {
        this.toast("仅开票失败的发票可删除，请重新勾选！", "waring");
        return;
      }
      setTimeout(() => {
        this.$refs.invoiceDelete.open();
      }, 0);
    },
    previewImg() {
      this.$nextTick(() => {
        this.$refs.preview.clickHandler();
      });
    },
    openFile() {
      document.getElementById("open").click();
    },
    changeFile() {
      const fu = document.getElementById("open");
      if (fu == null) return;
      this.importForm.fileName = fu.files[0].name;
      this.importForm.file = fu.files[0];
    },
    refreshDetail() {
      this.drawer = false;
      this.handleDetailInvoice();
    },
    //获取红票发票状态
    async handleSyncToRedStatus() {
      let items = this.selections;
      if (items.length < 1) {
        this.toast("请选择要同步的数据！", "waring");
        return;
      }
      this.ids = items.map((item) => item.id);
      const { success, data } = await handleSyncToRedInvoiceStatus(this.ids);
      if (success) {
        this.toast("请选择要同步的数据完成！", "success");
      }
    },
    // 重置筛选列表
    handleReset() {
      this.resetForm("queryForm");
      this.tags = "";
      this.queryForm.tags = "";
      this.queryForm.deptCode = undefined;
      this.queryForm.creatorName = undefined;
      this.queryForm.invoiceStatuses = [];
      if (this.eqpExtensionNo) {
        this.queryForm.equipmentExtensionNos = [this.eqpExtensionNo];
      }
      this.queryForm.sumAmount = undefined;
      this.billedRange = [];
    },
    // 收起筛选列表
    handleListClose() {
      this.selectExpended = false;
    },
    // 展开筛选列表
    handleListShow() {
      this.selectExpended = true;
    },
    //重置列设置
    handleCheckReset() {
      this.colOptions = this.colSelect;
      this.checkAll = true;
      this.isIndeterminate = false;
    },
    handleCheckAllChange(val) {
      this.colOptions = val ? this.colSelect : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.colSelect.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.colSelect.length;
    },
    reqListDictionary() {
      this.getDicts("sys_invoice_tax_rate").then(
        ({ data }) => (this.taxRates = data)
      );
    },
    // 获取开票设备
    async reqListEquipment() {
      this.eqpExtensionNo = undefined;
      const orgId = this.model.orgId;
      this.queryForm.orgId = orgId;
      const { success, data } = await listEquipmentByOrg(orgId);
      if (success) {
        let deft = data.filter((equipment) => equipment.defaulted);
        this.equipments = [...data];
        if (deft.length > 0) {
          this.eqpExtensionNo = deft[0].extensionNo;
        }
        if (this.eqpExtensionNo) {
          this.queryForm.equipmentExtensionNos = [this.eqpExtensionNo];
        }
      }
    },
    // 诺诺发票同步开票结果
    async handleSyncData(row) {
      const { success, data } = await syncInvoice(row.id);
      if (success && data)
        this.toast("发票结果同步成功", "success", () => this.handleQuery());
    },
    handleGetlineSet() {
      if (JSON.parse(localStorage.getItem("lineSet")) != null) {
        this.colData = JSON.parse(localStorage.getItem("lineSet"));
        if (
          this.colData.filter((i) => i.isTrue == true).length ===
          this.colSelect.length
        ) {
          this.checkAll = true;
          this.isIndeterminate = false;
        }
        this.colOptions = JSON.parse(localStorage.getItem("lineSet"))
          .filter((i) => i.isTrue == true)
          .map((item) => item.title); //选中
      } else {
        this.colOptions = this.colData.map((item) => item.title); //默认所有
        this.checkAll = true;
        this.isIndeterminate = false;
      }
    },
    changedSelectStatus() {
      this.selectStatus = !this.selectStatus;
    },
    // 已开发票统计
    async reqBillSummary() {
      this.dataLoading = true;
      const { success, data } = await billSummary(this.getQueryParam());
      if (success) {
        this.summary = data[0];
      }
      this.dataLoading = false;
    },
    // 已开发票查询
    async handleQuery() {
      this.orderSet = [];
      if (this.$refs.invoiceCode) {
        this.$refs.invoiceCode.reset();
      }
      if (this.$refs.invoiceNo) {
        this.$refs.invoiceNo.reset();
      }
      if (this.$refs.invoiceTime) {
        this.$refs.invoiceTime.reset();
      }
      if (this.$refs.dataId) {
        this.$refs.dataId.reset();
      }

      await this.reqBillSummary();
      this.dataTotal = this.summary.total;
      if (this.summary.total == 0) {
        this.tableData = [];
      }
      let offset = (this.queryForm.page - 1) * this.queryForm.size;
      if (this.summary.total > offset) {
        this.reqBillList();
      }
    },
    // 已开发票数据
    async reqBillList() {
      const orderBy =
        this.orderSet.length < 1
          ? undefined
          : this.orderSet
              .join(",")
              .replace(/([A-Z])/g, "_$1")
              .toLocaleLowerCase();
      this.queryForm.orderBy = orderBy;

      this.dataLoading = true;

      //查询 清空选择项
      this.queryForm.ids = [];
      if (this.tags.length) {
        this.queryForm.tags = this.tags.join(",");
      } else {
        this.queryForm.tags = "";
      }
      let queryParams = JSON.parse(JSON.stringify(this.getQueryParam()));
      const { success, data } = await billList(queryParams);
      this.dataLoading = false;
      if (success) {
        const newData = data.records.map((item) => {
          const tags = typeof item.tags === "string" && item.tags.split(",");
          const arr = [];
          for (let i = 0; i < this.businessPractice.length; i++) {
            for (let x = 0; x < tags.length; x++) {
              if (tags[x] === this.businessPractice[i].code) {
                arr.push(this.businessPractice[i].name);
              }
            }
          }
          item.tagsArr = arr;
          return item;
        });
        this.tableData = newData;
        this.dataTotal = data.total;
      }
    },
    getQueryParam() {
      return this.addDateRange(this.queryForm, this.billedRange, "Billed");
    },
    // 批量修改发票类型
    handleBillingType() {
      this.editBillingTypeParam.billingType = "";
      if (!this.selections.length > 0) {
        //勾选
        this.toast("至少选择一条需要修改发票类型的数据", "warning");
        return;
      }
      this.editBillingTypeParam.ids = this.selections.map((i) => i.id);
      if (
        this.selections.filter((item) => item.invoiceStatus != "KJSB").length >
        0
      ) {
        this.toast("只允许开票失败的发票，修改发票类型", "warning");
        return;
      }
      this.updateBillingTypeVisible = true;
    },
    // 确认修改发票类型
    confirmUpdateBillingType() {
      //调用修改接口
      this.handleUpdateBillingType();
      this.updateBillingTypeVisible = false;
    },
    // 修改发票类型
    async handleUpdateBillingType() {
      this.recordsLoading = true;
      const { success, message } = await updateBillingType(
        this.editBillingTypeParam
      );
      this.recordsLoading = false;
      if (success) {
        this.handleQuery();
        this.toast("修改发票类型成功！", "success");
      } else {
        this.toast(message, "warning");
        this.recordsLoading = false;
      }
    },
    //获取当前组织列表
    handleCurrentOrganizations(model) {
      this.editInvoiceOrgParam.orgId = model.orgId;
    },
    // 批量修改发票类型
    handleUpdateInvoiceOrg() {
      if (!this.selections.length > 0) {
        this.toast("至少勾选一条需要调整所属组织的发票", "warning");
        return;
      }
      //勾选
      this.editInvoiceOrgParam.ids = this.selections.map((i) => i.id);
      if (
        this.selections.filter((item) => item.invoiceStatus != "KJSB").length >
        0
      ) {
        this.toast("只允许开票失败的发票，修改所属组织", "warning");
        return;
      }
      this.updateInvoiceOrgVisible = true;
    },
    // 修改发票抬头
    handleUpdateInvoiceTitle() {
      let invoice = this.selections[0];
      let invoiceStatus = invoice.invoiceStatus;
      if (
        !(
          invoiceStatus == "KJSB" ||
          invoiceStatus == "WKP" ||
          invoiceStatus == "WSC"
        )
      ) {
        this.toast("所选择发票的发票状态不允许修改开票抬头信息", "warning");
        return;
      }
      this.invoiceTitle = JSON.parse(JSON.stringify(invoice));
      this.invoiceTitleVisible = true;
    },
    handleDownFile() {
      if (this.selections.length == 0) {
        this.toast("请勾选需要下载的信息", "warning");
        return;
      }
      let count = this.selections.filter(
        (item) => item.invoiceStatus != "YKJ" && item.invoiceStatus != "YCH"
      );
      if (count.length > 0) {
        this.toast("批量下载只允许下载已开具和已冲红的发票影像", "warning");
        return;
      }
      this.downloadInBatchesVisible = true;
    },

    handleEditTaxRateExplain() {
      if (this.selections.length < 1) {
        this.toast("修改失败，请选择要修改发票记录！", "warning");
        return;
      }

      this.$refs.editTaxRateExplainRef.showDialog(this.selections);
    },

    async handleUpdateSellerInfo() {
      if (this.selections.length == 0) {
        this.toast("请选择需要同步的发票信息", "warning");
        return;
      }
      let count = this.selections.filter(
        (item) => item.invoiceStatus != "KJSB"
      );
      if (count.length > 0) {
        this.toast("只允许同步[开具失败]的发票信息", "warning");
        return;
      }
      let param = {
        ids: this.selections.map((i) => i.id),
      };
      const rsp = await updateInvoiceSellerInfo(param);
      if (rsp.success) {
        this.toast("同步销方信息成功", "success");
      }
    },
    handlerDeleteInBatches() {},
    // 确定下载
    async handlerDownloadInBatches() {
      if (this.downFileTypeSet.length == 0) {
        this.toast("选择下载文件类型", "warning");
        return;
      }
      let param = {
        ids: this.selections.map((i) => i.id),
        imageTypes: this.downFileTypeSet,
        groupByRule: this.groupByRule,
      };
      this.toast("发票影像下载中,请稍后……", "success");
      this.downloadInBatchesVisible = false;
      const rsp = await invoiceDownload(param);
      this.downloadFile(rsp);
    },
    // 确认修改发票组织
    async confirmUpdateInvoiceOrg() {
      //调用修改接口
      this.recordsLoading = true;
      const { success, message } = await updateInvoiceOrg(
        this.editInvoiceOrgParam
      );
      this.recordsLoading = false;
      if (success) {
        this.handleQuery();
        this.toast("修改发票组织成功！", "success");
      } else {
        this.toast(message, "warning");
        this.recordsLoading = false;
      }
      this.updateInvoiceOrgVisible = false;
    },
    // 已开发票记录导出
    handleExport() {
      this.exportToastVisible = true;
    },
    // 修改发票状态
    updateInvoiceStatus() {
      this.updateInvoice = JSON.parse(JSON.stringify(this.selections[0]));
      setTimeout(() => {
        this.$refs.invoiceUpdateStatus.open();
      }, 0);
    },
    // 重置按钮修改状态
    handleUpdateReset() {
      this.updateInvoice = {};
    },
    handleUpdateQuery() {
      // this.toast('修改发票类型成功！', 'success');
      this.handleQuery();
    },
    toastClosed() {
      this.exportToastVisible = false;
    },
    async exportExcel() {
      this.exportToastVisible = false;
      this.exportDisabled = true;
      let items = this.selections;
      this.ids = items.map((item) => item.id);
      this.queryForm.colOptions = this.colOptions;
      //导出参数与 查询参数分割
      let param = this.queryForm;
      param.ids = this.ids;
      const rsp = await exportAlreadyInvoices(param);
      this.exportDisabled = false;
      this.downloadFile(rsp);
    },
    // 发票复制
    handelCopyInvoice(row) {
      this.checkData = row;
      setTimeout(() => {
        this.$refs.invoiceCopy.open();
      }, 0);
    },
    handelCopyInvoiceSuccess(invoice) {
      this.$emit("changeManual");
    },
    // 按钮可用/禁用
    handleInvoiceButtonDisabled(type, params) {
      switch (type) {
        case 1: // 冲红
          if (
            params.billingType == "QDPP" ||
            params.billingType == "QDZP" ||
            params.billingType == "Dzpp" ||
            params.billingType == "DZZP" ||
            params.billingType == "SGDP"
          ) {
            if (params.sumAmount > 0 && params.invoiceStatus == "YKJ") {
              this.RedInvoice = true;
              return false;
            }
          } else {
            if (
              params.invoiceStatus == "YKJ" &&
              this.dateFormat(new Date().toLocaleString(), "YYYY-MM") >
                this.dateFormat(params.invoiceTime, "YYYY-MM")
            ) {
              this.RedInvoice = true;
              return false;
            }
          }
          // this.RedInvoice = false
          return true;
        case 2: // 作废
          return (
            params.billingType == "QDPP" ||
            params.billingType == "QDZP" ||
            params.billingType == "Dzpp" ||
            params.billingType == "DZZP" ||
            (params.invoiceStatus != "YKJ" && params.invoiceStatus != "ZFSB")
          );
        case 3: // 交付/打印
          return (
            params.invoiceStatus == "KJZ" ||
            params.invoiceStatus == "CHZ" ||
            params.invoiceStatus == "ZFZ"
          );
        case 4: // 下载
          return !(
            params.invoiceStatus == "YKJ" || params.invoiceStatus == "YCH"
          );
        case 5: // 打印
          return !(
            params.invoiceStatus == "YKJ" ||
            params.invoiceStatus == "ZFSB" ||
            params.invoiceStatus == "YZF" ||
            params.invoiceStatus == "YCH" ||
            params.invoiceStatus == "CHSB"
          );
        case 6: // 复制
          return (
            params.billingType == "QDPP" ||
            params.billingType == "QDZP" ||
            params.dataFlag == "CXTS"
          );
        default:
          return false;
      }
    },
    // 发票详情
    handleDetailInvoice(invoice) {
      this.drawer = !this.drawer;
      selectInvoiceDetail(invoice.id).then((res) => {
        if (res.success) {
          this.invoice = res.data;
          this.invoice.industry = "retail";
          this.invoice.items.forEach(
            (item) => (item.taxRate = Number(item.taxRate) * 100 + "%")
          );
          // 统计信息
          // let sumAmount = this.invoice.items
          //   .map((item) => Number(item.amount))
          //   .reduce((sum, amount) => sum + amount, 0);
          // let sumTaxAmount = this.invoice.items
          //   .map((item) => Number(item.taxAmount))
          //   .reduce((sum, taxAmount) => sum + taxAmount, 0);
          // let total = {
          //   merchandiseName: "合计",
          //   amount: Number(sumAmount).toFixed(2),
          //   taxAmount: Number(sumTaxAmount).toFixed(2),
          // };
          // this.invoice.items.push(total);
          //let sum = Number(sumAmount + sumTaxAmount).toFixed(2);
          //let sumBig = this.handleSmallToCapitalize(sum);
          // let priceTax = {
          //   merchandiseName: "价税合计（大写）",
          //   specification: sumBig,
          //   unit: "（小写）￥" + sum,
          // };
          //this.invoice.items.push(priceTax);
          this.invoice.sendBackInfo = res.data;
        }
      });
    },
    // 发票冲红
    handleInvoiceTored(invoice) {
      this.checkData = invoice;
      setTimeout(() => {
        this.$refs.invoiceTored.open(invoice);
      }, 0);
    },
    // 发票作废
    handleInvoiceRevoke(invoice) {
      this.checkData = invoice;
      setTimeout(() => {
        this.$refs.invoiceRevoke.open();
      }, 0);
    },
    // 发票打印选择
    handleBeforeCommand(row, command) {
      return {
        row: row,
        command: command,
      };
    },
    // 发票下载
    async handleInvoiceDownLoad(row) {
      if (["BW-XML-SKP", "HX-XML-JSP"].indexOf(row.billingChannel) > -1) {
        this.toast("该票是百旺/航信-XML开具，请在开票软件中下载！", "warning");
        return;
      }
      if (["QD-RPA"].indexOf(row.billingChannel) > -1) {
        window.open(row.billingInvoiceUrl, "_blank");
        return;
      }
      const rsp = await downloadInvoice(row.id);
      this.downloadFile(rsp);
    },
    // 发票打印
    handlePrint(row) {
      this.printInvoices.splice(0, this.printInvoices.length, row);
      this.$refs.invoicePrint.openPrint();
    },
    handlePrintActions(flag) {
      this.$refs.invoicePrint.openSetup(flag);
    },
    handleBatchPrint() {
      this.printInvoices.splice(
        0,
        this.printInvoices.length,
        ...this.selections
      );
      this.$refs.invoicePrint.openPrint();
    },
    handleUpdateVersion() {
      this.iuvInvoices.splice(0, this.iuvInvoices.length, ...this.selections);
      this.$refs.invoiceItemUpdateVersion.open();
    },
    async handleElectronPrint(type) {
      if (this.selections.length < 1) {
        this.toast("请先选择发票数据！", "warning");
        return;
      }
      let ids = this.selections.filter(
        (item) =>
          item.billingType != "DZZP" &&
          item.billingType != "Dzpp" &&
          item.billingType != "QDZP" &&
          item.billingType != "QDPP"
      );
      if (ids.length > 0) {
        this.toast("您所选发票中包含了非电子发票，不能进行打印", "warning");
        return;
      }

      const idsArr = this.selections.map((item) => item.id).join(",");
      let param = {
        ids: idsArr,
        type,
      };
      const loading = this.$loading({
        lock: true,
        text: "加载中请稍后。。。",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const { success, data } = await querySingleInvoice(param);
      loading.close();

      if (success) {
        this.$nextTick(() => {
          this.printPdfData = data;
          const tmpData = data.split("data:application/pdf;base64,");
          setTimeout(() => {
            printJs({ printable: tmpData[1], type: "pdf", base64: true });
          }, 500);
        });
      }
    },
    // 发票交付
    handleDeliver(row) {
      this.sendInvoices.splice(0, this.sendInvoices.length, row);
      this.$refs.invoiceSend.open();
    },
    handleBatchDeliver() {
      this.sendInvoices.splice(0, this.sendInvoices.length, ...this.selections);
      this.$refs.invoiceSend.open();
    },
    // 批量开具
    handleBatchBill() {
      this.sendInvoices.splice(0, this.sendInvoices.length, ...this.selections);
      if (this.sendInvoices.length < 1) {
        this.toast("请至少勾选一条发票", "warning");
        return;
      }
      let noBillingFail = this.sendInvoices.filter(
        (item) => item.invoiceStatus != "KJSB"
      );
      if (noBillingFail.length > 0) {
        this.toast("只能选择开具失败的发票重新开票", "warning");
        return;
      }
      this.billBatchVisible = true;
    },
    handleBatchBillSuccess() {
      this.handleQuery();
    },
    // 表格勾选
    handleSelectionChange(val) {
      let ids = [];
      this.selections = val;
    },
    // 发票开具
    handleInvoiceIssue(invoice) {
      this.failRedInvoice = false;
      this.issueVisible = true;
      this.issueData = JSON.parse(JSON.stringify(invoice));
      console.log(this.issueData, "issueData");
      // this.issueData.isRedInvoice = true
      this.KPJL = "KPJL";
      if (invoice.invoiceStatus == "KJSB" && invoice.amount < 0) {
        this.failRedInvoice = true;
      } else if (
        (invoice.billingType == "QDPP" ||
          invoice.billingType == "QDZP" ||
          invoice.billingType == "Dzpp" ||
          invoice.billingType == "DZZP" ||
          invoice.billingType == "SGDP") &&
        invoice.invoiceStatus == "YKJ"
      ) {
        this.failRedInvoice = true;
        this.KPJL = "KPJL";
        this.issueData.sumAmount = this.issueData.sumAmount * -1;
        this.issueData.amount = this.issueData.amount * -1;
      } else if (
        invoice.invoiceStatus == "YKJ" &&
        this.dateFormat(new Date().toLocaleString(), "YYYY-MM") >
          this.dateFormat(invoice.invoiceTime, "YYYY-MM")
      ) {
        this.failRedInvoice = true;
        this.issueData.sumAmount = this.issueData.sumAmount * -1;
        this.issueData.amount = this.issueData.amount * -1;
      } else {
        this.failRedInvoice = false;
      }
    },
    async handleBillingInvoice(printable, back) {
      console.log(this.issueData, "this.issueData");
      const { success } = await rebillInvoice(this.issueData.id);
      if (success) {
        if (back) back(success);
      }
      this.issueVisible = false;
    },
    transition(type, value) {
      return this.FmtEnum(type, value);
    },
    FmtEnum(enumState = [], enumValue) {
      const enumItem = enumState.find((item) => item.uniqueCode == enumValue);
      if (enumItem) return enumItem.name;
      return "--";
    },
    // 表格值格式化
    handleTableValueFormat(row, column) {
      let value = row[column.property];
      switch (column.label) {
        case "开票日期":
          if (value == undefined) {
            return "";
          }
          return this.$moment(value).format("YYYY-MM-DD");
        case "发票类型":
          return this.handleValueToLabel("BillingType", value);
        case "所属部门":
          return this.transition(this.departmentListS, value);
        case "交付状态":
          if (row.deliverStatus == "YJF") {
            return "已交付";
          }
          return "未交付";
        case "回写状态":
          if (row.writeBackStatus == "INIT") {
            return "初始状态";
          } else if (row.writeBackStatus == "HXSB") {
            return "回写失败";
          } else if (row.writeBackStatus == "HXCG") {
            return "回写成功";
          }
          return "";
        case "开票标识":
          if (row.billingFlag == "Lp") {
            return "蓝票";
          } else {
            return "红票";
          }
        case "发票金额":
        case "发票税额":
        case "价税合计":
          return fmtCurrency(value, 2, "");
        case "业务类型":
          return this.handleValueToLabel("BillingFrom", value);
        case "打印状态":
          return row.billingType == "Dzpp" || row.billingType == "DZZP"
            ? "--"
            : this.handleValueToLabel("PrintingStatus", value);
        case "开票人员":
          if (value != null && value != "") {
            return value;
          }
          return "--";
        case "订单来源":
          if (value != null && value != "") {
            return value;
          }
          return "正常开票";
        case "主税率值":
          return isNaN(value) ? value : value == "" ? "" : `${value * 100}%`;
        case "已经重开":
          if ("Y" === value) {
            return "是";
          }
          return "否";
        default:
          return value;
      }
    },
    // 发票状态颜色
    handleBillingTypeColor(billingType) {
      switch (billingType) {
        case "KJSB":
        case "YCH":
        case "YZF":
          return "status-color";
        default:
          return "";
      }
    },
    // 切换分页条数
    handleSizeChange(value) {
      this.queryForm.size = value;
      this.handleQuery();
    },
    // 翻页
    handleCurrentChange(value) {
      this.queryForm.page = value;
      this.handleQuery();
    },
    // 发票删除
    handleInvoiceDelete(invoice) {
      if (invoice.invoiceStatus == "KJSB") {
        this.toast(
          invoice.failedMessage +
            "，请点击此发票右侧『开票』按钮再次进行开具！",
          "success"
        );
      }
      this.deleteInvoices = [invoice];
      setTimeout(() => {
        this.$refs.invoiceDelete.open();
      }, 0);
    },
    fmtBillingType(val) {
      return this.handleValueToLabel("BillingType", val);
    },
    // 复制开票失败原因
    handleCopyMessage(row, column, cell, event) {
      if (
        row.failedMessage &&
        "KJSB" == row.invoiceStatus &&
        "失败原因" == column.label
      ) {
        let domUrl = document.createElement("input");
        domUrl.value = row.failedMessage;
        domUrl.id = "creatDom";
        document.body.appendChild(domUrl);
        domUrl.select(); // 选择对象
        document.execCommand("Copy"); // 执行浏览器复制命令
        let creatDom = document.getElementById("creatDom");
        creatDom.parentNode.removeChild(creatDom);
        this.toast("复制成功", "success");
      }
    },
    // 列表排序
    handleSort({ prop, order }) {
      let index = this.orderSet.findIndex((i) =>
        eval(`/${prop.property}/`).test(i)
      );
      if (index > -1) {
        this.orderSet.splice(index, 1);
      }
      if (order) {
        this.orderSet.push(`${prop.property}\u0020${order}`);
      }
      this.reqBillList();
    },
    uploadFile(row) {
      this.uploadFileVisible = true;
      this.invoiceFiles.id = row.id;
    },
    uploadInvoiceFile(file, row) {
      switch (row) {
        case "xml":
          this.invoiceFiles.xmlFile = file.raw;
          this.invoiceFiles.xmlFileName = file.name;
          break;
        case "pdf":
          this.invoiceFiles.pdfFile = file.raw;
          this.invoiceFiles.pdfFileName = file.name;
          break;
        case "ofd":
          this.invoiceFiles.ofdFile = file.raw;
          this.invoiceFiles.ofdFileName = file.name;
          break;
      }
    },
    uploadFileVisibleClose() {
      this.uploadFileVisible = false;
      this.resetInvoiceFiles();
    },
    //提交发票文件
    async uploadInvoiceFileSubmit() {
      let formData = new FormData();
      if (this.invoiceFiles.xmlFile != null) {
        formData.append("xmlFile", this.invoiceFiles.xmlFile);
      }
      if (this.invoiceFiles.pdfFile != null) {
        formData.append("pdfFile", this.invoiceFiles.pdfFile);
      }
      if (this.invoiceFiles.ofdFile != null) {
        formData.append("ofdFile", this.invoiceFiles.ofdFile);
      }
      formData.append("id", this.invoiceFiles.id);
      const res = await uploadInvoiceOriginFile(formData);
      if (res.success) {
        this.toast("发票文件导入成功", "success");
      }
      this.uploadFileVisible = false;
      this.resetInvoiceFiles();
    },
    //重置发票文件参数
    resetInvoiceFiles() {
      this.invoiceFiles.xmlFile = null;
      this.invoiceFiles.xmlFileName = "未选择任何文件";
      this.invoiceFiles.ofdFile = null;
      this.invoiceFiles.ofdFileName = "未选择任何文件";
      this.invoiceFiles.pdfFile = null;
      this.invoiceFiles.pdfFileName = "未选择任何文件";
      this.invoiceFiles.id = null;
    },
    downloadInvoiceFileDialog(row) {
      this.downloadFileVisible = true;
      this.invoiceFileId.ofdFileId = row.originOdfFileId;
      this.invoiceFileId.pdfFileId = row.originalFileId;
      this.invoiceFileId.xmlFileId = row.originXmlFileId;
      this.downloadInvoiceFileArg.id = row.id;
    },
    //下载发票文件
    async handleInvoiceFileDownLoad(id, type) {
      this.downloadInvoiceFileArg.imageType = type;
      this.downloadInvoiceFileArg.originalFileId = id;
      const rsp = await downloadInvoiceFile(this.downloadInvoiceFileArg);
      this.downloadFile(rsp);
    },
    //是否存在附件
    isExistInvoiceFile(row) {
      let count = 0;
      if (row.originOdfFileId > 0) {
        count++;
      }
      if (row.originalFileId > 0) {
        count++;
      }
      if (row.originXmlFileId > 0) {
        count++;
      }
      if (count > 0) {
        return "是(" + count + ")";
      } else {
        return "否(0)";
      }
    },
    async rpaExport() {
      let arr = [];
      let total = 0;
      if (this.selections.length > 0) {
        for (const inv of this.selections) {
          if (inv.invoiceStatus !== "KJZ" && inv.invoiceStatus !== "KJSB") {
            this.$confirm(
              "只能导出开票状态为[开具中]或[开具失败]的发票，请检查后重新导出",
              "提示"
            );
            return;
          }
          if (inv.billingType !== "QDZP" && inv.billingType !== "QDPP") {
            this.$confirm(
              "只能导出开票类型为[数电普票]或[数电专票]的发票，请检查后重新导出",
              "提示"
            );
            return;
          }
        }
        total = this.selections.length;
        this.queryForm.ids = this.selections.map((item) => item.id);
      } else {
        //当前查询条件全部
        total = this.summary.total;
      }

      this.$confirm(
        "您是否要导出已选中或查询出来" + total + "条的数据？",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          const { success } = rpaExport(this.getQueryParam()).then((res) => {
            let blob = new Blob([res.data], { type: "application/xlsx" });
            let url = window.URL.createObjectURL(blob);
            const link = document.createElement("a"); // 创建a标签
            link.href = url;
            link.download = "全电导出模板.xlsx"; // 重命名文件
            link.click();
            URL.revokeObjectURL(url);
          });
          if (success) {
            this.toast("导出成功！", "success");
          }
          this.queryForm.ids = [];
        })
        .catch(() => {});
    },
    async rpaImport() {
      if (this.importForm.fileName === "") {
        this.toast("请选择导入文件", "error");
        return;
      }
      if (this.importForm.date == "") {
        this.toast("请选择导入时间", "error");
        return;
      }
      this.centerDialogVisible = false;
      let formData = new FormData();
      formData.append("file", this.importForm.file);
      formData.append(
        "date",
        this.$moment(this.importForm.date).format("YYYY-MM-DD HH:mm:ss")
      );
      const { success, message, data } = await rpaImport(formData);
      if (success) {
        this.resultExport(data);
      }
    },
    resultExport(data) {
      this.$confirm("文件导入完成", "提示", {
        confirmButtonText: "下载导入结果",
        cancelButtonText: "取消",
      })
        .then(() => {
          const { success } = importResultExport(data).then((res) => {
            let blob = new Blob([res.data], { type: "application/xlsx" });
            let url = window.URL.createObjectURL(blob);
            const link = document.createElement("a"); // 创建a标签
            link.href = url;
            link.download = "全电开票导入结果.xlsx"; // 重命名文件
            link.click();
            URL.revokeObjectURL(url);
          });
          this.importForm.file = {};
          this.importForm.fileName = "";
          this.importForm.date = "";
        })
        .catch(() => {});
    },
    syncFile() {},
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/select.scss";

.content-select {
  .select-body {
    padding: 24px 24px 24px 24px;
  }

  .select-content {
    .select-form {
      .select-label label {
        width: 90px !important;
      }
    }
  }

  .content-select {
    .select-body {
      padding: 24px 24px 24px 24px;
    }

    .select-status {
      display: inline-flex;
      flex-direction: row;
      justify-content: space-between;
      color: #3d94ff;
    }

    .select-status:hover {
      cursor: pointer;
    }
  }

  .content-main {
    min-height: calc(100% - 120px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .data-id {
      text-align: left;
    }

    .manual-icon {
      vertical-align: middle;
      margin-right: 4px;
      height: 16px;
      width: 16px;
    }

    .status-color {
      color: #f5222d;
    }

    .operate-button {
      display: flex;
      justify-content: flex-start;

      .el-button {
        padding: 0;
        margin: 0 0 0 10px;
      }

      .el-dropdown-link {
        cursor: pointer;
        color: #409eff;
        margin-left: 10px;
      }

      .el-icon-arrow-down {
        font-size: 12px;
      }
    }
  }

  .dialog-body {
    margin: -16px 24px 0 24px;

    .body-top {
      .top-first-row {
        display: flex;
        margin-bottom: 12px;
      }

      .top-second-row {
        display: flex;
        text-align: left;
        margin-bottom: 24px;

        span {
          display: inline-block;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 20em;
        }
      }

      label {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
      }

      span {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 20px;
      }
    }

    .body-bottom {
      text-align: left;
      border-top: 1px solid #e9e9e9;
      margin: 0 -24px;
      padding: 24px;

      span {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #f5222d;
        line-height: 20px;
      }
    }
  }

  .el-dialog__footer {
    padding: 10px 24px 24px;
    text-align: right;
    box-sizing: border-box;
  }

  .dialog-message {
    padding-left: 20px;
    text-align: left;
  }

  .copy-dialog {
    margin-top: -20px;
    padding: 0 20px;

    .copy-title {
      color: #f5222d;
    }
  }

  .payer {
    width: 226px;
    margin-left: 14px;
  }
}

.tips {
  width: 16px;
  height: 16px;
}

.invoiceStatus {
  display: flex;
  align-items: center;
}

.part {
  width: 100%;
  height: 1px;
  background: #e5e5e5;
  margin: 15px 0;
}

.main-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .header-left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .header-right > button {
    margin-right: 10px;
  }
}

.main-top {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;

  .set {
    width: 20px;
    height: 20px;
    margin-left: 14px;
    margin-right: 2px;
  }
}

.operate-button {
  .el-button {
    height: 32px;
    line-height: 32px;
    padding: 0 1px;
    font-weight: 400;
  }
}

.main-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .header-left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .header-right > button {
    margin-right: 10px;
  }
}

.main-top {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;

  .set {
    width: 20px;
    height: 20px;
    margin-left: 14px;
    margin-right: 2px;
  }
}

.content-table {
  .table-statistics {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ebf4ff;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 20px;

    .statistics-left {
      display: flex;
      justify-content: space-between;

      div {
        margin-right: 20px;
      }

      div:first-child {
        margin-left: 15px;
      }
    }

    .statistics-right {
      display: flex;
      justify-content: space-between;

      div {
        margin-left: 20px;
      }

      div:last-child {
        margin-right: 20px;
      }
    }
  }
}

.operate-button {
  .el-button {
    height: 32px;
    line-height: 32px;
    padding: 0 1px;
    font-weight: 400;
  }
}

.print-config {
  margin-top: -30px;
  padding: 0 24px;
  text-align: left;

  ::v-deep .el-input__inner {
    width: 232px;
  }

  .print-select {
    ::v-deep .el-input__inner,
    ::v-deep .el-select,
    ::v-deep .el-input {
      width: 300px;
    }
  }
}

.dataId-icon {
  vertical-align: middle;
  margin-right: 4px;
  height: 16px;
  width: 16px;
}

.status-color {
  color: #f5222d;
}

.batch-wrap {
  margin-top: -24px;

  .content {
    display: flex;
    color: #333333;
    font-size: 14px;
    padding: 0 24px;
    flex-direction: column;
    align-items: flex-start;

    div {
      padding-bottom: 24px;
    }

    label {
      color: #666666;
    }
  }

  .batch-footer {
    padding: 0 24px 24px;
    text-align: right;
  }
}

.export-select {
  margin-bottom: 24px;

  span {
    margin-right: 8px;
  }
}

.invoice-file-name {
  width: 200px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.upload-file {
  label {
    width: 80px;
  }
  display: flex;
  height: 35px;
  line-height: 35px;
  margin-top: 10px;
}
.even_style {
  ::v-deep .el-dropdown-menu__item {
    line-height: 34px;
    font-size: 14px;
  }
}
.select-content {
  ::v-deep .el-select .el-tag {
    max-width: 70%;
  }
  ::v-deep .select-form {
    width: auto;
    display: block;
  }

  ::v-deep .select-form > div {
    display: inline-flex;
  }
}
.edit_label {
  ::v-deep .el-dialog .el-dialog__body {
    text-align: left;
    padding: 0px 0 0 20px;
    font-size: 16px;
  }
  ::v-deep .el-dialog__body {
    margin-top: 30px;
  }
  ::v-deep .el-tag + .el-tag {
    margin-left: 10px;
  }
  ::v-deep .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  ::v-deep .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
  // .addible {
  //   .el-tag
  // }
}
::v-deep .el-range-editor--mini .el-range-separator {
  line-height: inherit;
}
.el-date-picker {
  width: 430px !important;
}
</style>
<style lang="scss">
.el-table__row td.dl-pointer {
  cursor: pointer;
  color: #409eff;
}
.invoice-tags {
  .invoice-tags-select {
    input {
      height: 32px !important;
    }
  }
  .invoice-tags-condition {
    .el-input {
      width: 60px;
    }
  }
}
</style>
