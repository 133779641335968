/*=============  发票相关常量  =================*/
/**
 * 票据类型
 */
export const INVOICE_TYPES = [
  {
    value: 'SpecInvoice',
    label: '增值税专用发票'
  },
  {
    value: 'GenerInvoice',
    label: '增值税普通发票'
  },
  {
    value: 'EleSpecInvoice',
    label: '增值税电子专用发票'
  },
  {
    value: 'EleInvoice',
    label: '增值税电子普通发票'
  },
  {
    value: 'EleTollInvoice',
    label: '增值税电子发票（通行费）'
  },
    {
        value: 'FullEleSpecInvoice',
        label: '电子发票(增值税专用发票)'
    },
    {
        value: 'FullEleGenerInvoice',
        label: '电子发票(普通发票)'
    },
    {
        value: 'FullElePaperSpecInvoice',
        label: '全电纸质发票（增值税专用发票）'
    },
    {
        value: 'FullElePaperGenerInvoice',
        label: '全电纸质发票（普通发票）'
    },
  {
    value: 'VolInvoice',
    label: '增值税普通发票(卷票)'
  },
  {
    value: 'VehInvoice',
    label: '机动车销售统一发票'
  },
  {
    value: 'EVehInvoice',
    label: '机动车销售电子统一发票'
  },
  {
    value: 'QuotaInvoice',
    label: '定额发票'
  },
  {
    value: 'PlaneInvoice',
    label: '飞机票'
  },
  {
    value: 'TrainInvoice',
    label: '火车票'
  },
  {
    value: 'PassengerInvoice',
    label: '客运票'
  },
  {
    value: 'TaxiInvoice',
    label: '出租车票'
  },
  {
    value: 'GenerMachineInvoice',
    label: '通用机打发票'
  },
  {
    value: 'TollInvoice',
    label: '过路费'
  },
  {
    value: 'TransportInvoice',
    label: '货物运输业增值税专用发票'
  },
  {
    value: 'UsedCarInvoice',
    label: '二手车销售统一发票'
  },
  {
    value: 'EleUsedCarInvoice',
    label: '二手车销售电子统一发票'
  },
  {
    value: 'PaPerFullEleSpecInvoice',
    label: '全电纸质发票（增值税专用发票）'
  },
  {
    value: 'PaPerFullEleGenerInvoice',
    label: '全电纸质发票（普通发票）'
  },
  {
    value: 'PaPerVehInvoice',
    label: '纸质发票（机动车销售统一发票）'
  },
  {
    value: 'PaPerUsedCarInvoice',
    label: '纸质发票（二手车销售统一发票）'
  },
  {
    value: 'ElePlaneInvoice',
    label: '电子发票（航空运输客票电子行程单）'
  },
  {
    value: 'EleTrainInvoice',
    label: '电子发票（铁路电子客票）'
  },

];

/**
 * 票据类型：手工录入
 */
export const MANUAL_INVOICE_TYPES = [
  {
    value: 'SpecInvoice',
    label: '增值税专用发票'
  },
  {
    value: 'EleSpecInvoice',
    label: '增值税电子专用发票'
  },
  {
    value: 'GenerInvoice',
    label: '增值税普通发票'
  },
  {
    value: 'EleInvoice',
    label: '增值税电子普通发票'
  },
  {
    value: 'EleTollInvoice',
    label: '增值税电子发票(通行费)'
  },
  {
    value: 'VolInvoice',
    label: '增值税普通发票(卷票)'
  },
  {
    value: 'FullEleSpecInvoice',
    label: '电子发票（增值税专用发票）'
  },
  {
    value: 'FullEleGenerInvoice',
    label: '电子发票（普通发票）'
  },
  {
    value: 'VehInvoice',
    label: '机动车销售统一发票'
  }
  // {
  //   value: 'FullEleSpecInvoice',
  //   label: '电子发票（增值税专用发票）'
  // },
  // {
  //   value: 'FullEleGenerInvoice',
  //   label: '电子发票（普通发票）'
  // }
];

/*抵扣票据类型*/
export const DEDECTION_INVOICE_TYPES = [
  {
    value: '',
    label: '全部'
  },
  {
    value: 'TrainInvoice',
    label: '火车票'
  },
  {
    value: 'PlaneInvoice',
    label: '飞机票'
  },
  {
    value: 'PassengerInvoice',
    label: '客运票'
  },
  {
    value: 'GenerInvoice',
    label: '增值税普通发票'
  },
  {
    value: 'EleInvoice',
    label: '增值税电子普通发票'
  }
];
/*识别票据类型*/
export const RECOGNIZE_INVOICE_TYPES = [
  {
    value: 'SpecInvoice',
    label: '增值税专用发票'
  },
  {
    value: 'GenerInvoice',
    label: '增值税普通发票'
  },
  {
    value: 'EleInvoice',
    label: '增值税电子发票'
  },
  {
    value: 'EleTollInvoice',
    label: '增值税电子发票（通行费）'
  },
  {
    value: 'VolInvoice',
    label: '增值税卷式发票'
  },
  {
    value: 'VehInvoice',
    label: '机动车销售统一发票'
  },
  {
    value: 'VatInvoiceList',
    label: '增值税发票销货清单'
  },
  {
    value: 'QuotaInvoice',
    label: '定额发票'
  },
  {
    value: 'PlaneInvoice',
    label: '飞机票'
  },
  {
    value: 'TrainInvoice',
    label: '火车票'
  },
  {
    value: 'PassengerInvoice',
    label: '客运票'
  },
  {
    value: 'TaxiInvoice',
    label: '出租车票'
  },
  {
    value: 'GenerMachineInvoice',
    label: '通用机打发票'
  },
  {
    value: 'TollInvoice',
    label: '过路费'
  }
];
/*识别票据状态*/
export const RECOGNIZE_INVOICE_STATUS = [
  // {
  //   value: '',
  //   label: '全部'
  // },

  {
    value: 'REPEAT',
    label: '识别重复'
  },
  {
    value: 'FAILURE',
    label: '识别失败'
  },
  {
    value: 'SUCCESS',
    label: '识别成功'
  },
  {
    value: 'UNMATCHED',
    label: '未匹配发票'
  }
];
/*识别票据状态*/
export const INVOICE_IMAGE_TYPE = [
  // {
  //   value: '',
  //   label: '全部'
  // },

  {
    value: 'IMG',
    label: 'IMG'
  },
  {
    value: 'PDF',
    label: 'PDF'
  },
  {
    value: 'OFD',
    label: 'OFD'
  },
  {
    value: 'XML',
    label: 'XML'
  },
  {
    value: 'ZIP',
    label: 'ZIP'
  }
];
/*识别票据状态*/
export const RECOGNIZE_FAILURE_CHANNEL = [
  {
    value: '',
    label: '全部'
  },
  {
    value: 'PICTURE',
    label: '图片上传'
  },
  {
    value: 'PDF',
    label: 'PDF文件'
  },
  {
    value: 'SCANNER',
    label: '扫描仪'
  }
];
/*发票盖章状态*/
export const RECOGNIZE_INVOICE_SEAL = [
  {
    value: true,
    label: '已盖章'
  },
  {
    value: false,
    label: '未盖章'
  }
];
/*开具票据类型*/
export const OPEN_INVOICE_TYPES = [
  {
    value: '',
    label: '全部'
  },
  {
    value: 'Dzpp',
    label: '电子普票'
  },
  {
    value: 'DZZP',
    label: '电子专票'
  },
  {
    value: 'Zzpp',
    label: '纸质普票'
  },
  {
    value: 'Zzzp',
    label: '纸质专票'
  },
  {
    value: 'SGDP',
    label: '收购电票'
  },
  {
    value: 'SGZP',
    label: '收购纸票'
  },
  {
    value: 'QDZP',
    label: '电子发票（增值税专用发票）'
  },
  {
    value: 'QDPP',
    label: '电子发票（普通发票）'
  }
];

/**
 * 发票状态
 * @type {{label: string, value: string})[]}
 */
export const INVOICE_STATUS = [
  {
    value: 'KJZ',
    label: '开具中'
  },
  {
    value: 'YKJ',
    label: '已开具'
  },
  {
    value: 'CHZ',
    label: '冲红中'
  },
  {
    value: 'YCH',
    label: '已冲红'
  },
  {
    value: 'ZFZ',
    label: '作废中'
  },
  {
    value: 'YZF',
    label: '已作废'
  },
  {
    value: 'KJSB',
    label: '开具失败'
  },
  {
    value: 'ZFSB',
    label: '作废失败'
  },
  {
    value: 'CHSB',
    label: '冲红失败'
  }
];

/**
 * 交付状态
 * @type {{label: string, value: string})[]}
 */
export const DELIVER_STATUS = [
  {
    value: '',
    label: '全部'
  },
  {
    value: 'YJF',
    label: '已交付'
  },
  {
    value: 'WJF',
    label: '未交付'
  }
];

/**
 * RECOGNIZE_FAILURE_RECORD_LINE
 * 发票识别失败列表字段
 */
export const RECOGNIZE_FAILURE_RECORD_LINE = [
  {
    title: '发票号码',
    isTrue: true
  },
  {
    title: '发票代码',
    isTrue: true
  },
  {
    title: '识别批次',
    isTrue: true
  },
  {
    title: '识别批次位置',
    isTrue: true
  },
  {
    title: '识别状态',
    isTrue: true
  },
  {
    title: '收票时间',
    isTrue: true
  },
  {
    title: '发票类型',
    isTrue: true
  },
  {
    title: '收票方式',
    isTrue: true
  },
  {
    title: '开票日期',
    isTrue: true
  },
  {
    title: '金额(不含税)',
    isTrue: true
  },
  {
    title: '校验码后六位',
    isTrue: true
  },
  {
    title: '总页数',
    isTrue: true
  },
  {
    title: '当前页数',
    isTrue: true
  },
  {
    title: '上传组织',
    isTrue: true
  },
  {
    title: '操作',
    isTrue: true
  }
];

/**
 * INVOICE_CENTER_LINE
 * 收票中心列表字段
 */
export const INVOICE_CENTER_LINE = [
  {
    title: '发票类型',
    isTrue: true
  },
  {
    title: '发票代码',
    isTrue: true
  },
  {
    title: '发票号码',
    isTrue: true
  },
  {
    title: '价税合计',
    isTrue: true
  },
  {
    title: '金额',
    isTrue: true
  },
  {
    title: '税额',
    isTrue: true
  },
  {
    title: '购方名称',
    isTrue: true
  },
  {
    title: '购方信息稽核',
    isTrue: true
  },
  {
    title: '销方名称',
    isTrue: true
  },
  {
    title: '开票日期',
    isTrue: true
  },
  {
    title: '收票时间',
    isTrue: true
  },
  {
    title: '查验状态',
    isTrue: true
  },
  {
    title: '发票状态',
    isTrue: true
  },
  {
    title: '验章状态',
    isTrue: true
  },
  {
    title: '结算状态',
    isTrue: true
  },
  {
    title: '稽核状态',
    isTrue: true
  },
  {
    title: '抵扣状态',
    isTrue: true
  },
  {
    title: '是否有清单',
    isTrue: true
  },
  {
    title: '识别批次',
    isTrue: true
  },
  {
    title: '影像类型',
    isTrue: true
  },
  {
    title: '上传组织',
    isTrue: true
  },
  {
    title: '上传者',
    isTrue: true
  },
  {
    title: '发票说明',
    isTrue: true
  },
  {
    title: '操作',
    isTrue: true
  }
];

/**
 * RETAIL_ALREADY_LINE
 * 开票记录列表字段
 */
export const RETAIL_ALREADY_LINE = [
  {    title: '订单编号',
    isTrue: true
  },
  {
    title: '购买方名称',
    isTrue: true
  },
  {
    title: '购买方税号',
    isTrue: true
  },
  {
    title: '发票代码',
    isTrue: true
  },
  {
    title: '发票号码',
    isTrue: true
  },
  {
    title: '开票日期',
    isTrue: true
  },
  {
    title: '金额',
    isTrue: true
  },
  {
    title: '税额',
    isTrue: true
  },
  {
    title: '价税合计',
    isTrue: true
  },
  {
    title: '税率',
    isTrue: true
  },
  {
    title: '发票类型',
    isTrue: true
  },
  {
    title: '发票状态',
    isTrue: true
  },
  {
    title: '失败原因',
    isTrue: true
  },
  {
    title: '业务类型',
    isTrue: true
  },
  {
    title: '打印状态',
    isTrue: true
  },
  {
    title: '税控设备',
    isTrue: true
  },
  {
    title: '开票人',
    isTrue: true
  },
  {
    title: '交付状态',
    isTrue: true
  },
  {
    title: '是否存在附件',
    isTrue: true
  },
  {
    title: '操作',
    isTrue: true
  },
  {
    title: '订单来源',
    isTrue: true
  },
  {
    title: '发票备注',
    isTrue: true
  },
  {
    title: '开票识别',
    isTrue: true
  },
  {
    title: '回写状态',
    isTrue: true
  },
  {
    title: '数电发票号码',
    isTrue: true
  },
  {
    title: '是否已经重开',
    isTrue: true
  },
  {
    title: '销方名称',
    isTrue: true
  },
  {
    title: '所属部门',
    isTrue: true
  },
  {
    title: '发票标签',
    isTrue: true
  },
  {
    title: '操作人',
    isTrue: true
  },
  {
    title: '业务单号',
    isTrue: true
  },
  {
    title: '发票流水号',
    isTrue: true
  },
  {
    title: '回写失败原因',
    isTrue: true
  }
];

/**
 * HOTEL_RETAIL_ALREADY_LINE
 * 开票记录列表字段（酒店）
 */
export const HOTEL_RETAIL_ALREADY_LINE = [
  {
    title: '订单编号',
    isTrue: true
  },
  {
    title: '确认号',
    isTrue: true
  },
  {
    title: '房间号',
    isTrue: true
  },
  {
    title: '客户名称',
    isTrue: true
  },
  {
    title: '客户税号',
    isTrue: true
  },
  {
    title: '发票代码',
    isTrue: true
  },
  {
    title: '发票号码',
    isTrue: true
  },
  {
    title: '开票日期',
    isTrue: true
  },
  {
    title: '价税合计',
    isTrue: true
  },
  {
    title: '发票类型',
    isTrue: true
  },
  {
    title: '发票状态',
    isTrue: true
  },
  {
    title: '失败原因',
    isTrue: true
  },
  {
    title: '数据来源',
    isTrue: true
  },
  {
    title: '取票方式',
    isTrue: true
  },
  {
    title: '打印状态',
    isTrue: true
  },
  {
    title: '更新时间',
    isTrue: true
  },
  {
    title: '开票人',
    isTrue: true
  },
  {
    title: '操作',
    isTrue: true
  },
  {
    title: '发票流水号',
    isTrue: true
  }
];

/**
 * 发票状态
 */
export const VAT_INVOICE_STATUS = JSON.parse(localStorage.getItem('enums')).VatInvoiceStatus;

/*稽核销方税号是否匹配*/
export const AUDIT_SELLER_TAX_NO_IS_MATCH = [
  {
    value: '',
    label: '全部'
  },
  {
    value: true,
    label: '已匹配'
  },
  {
    value: false,
    label: '未匹配'
  }
];

/*原始订单开具票据类型*/
export const ORIGINAL_ORDER_BILLING_TYPES = [
  {
    value: 'Zzpp',
    label: '纸质普票'
  },
  {
    value: 'Zzzp',
    label: '纸质专票'
  },
  {
    value: 'Dzpp',
    label: '电子普票'
  },
  {
    value: 'DZZP',
    label: '电子专票'
  },
  {
    value: 'SGDP',
    label: '收购发票（电子）'
  },
  {
    value: 'SGZP',
    label: '收购发票（纸质）'
  }
];

/*待开发票数据来源*/
export const BILLING_TRADE_SOURCE = [
  {
    value: '',
    label: '全部'
  },
  {
    value: 'XTZS',
    label: '系统自身'
  },
  {
    value: 'FZBK',
    label: '菲住布渴'
  },
  {
    value: 'SJXX',
    label: '石基信息'
  },
  {
    value: 'BZFW',
    label: '标准服务'
  },
  {
    value: 'WXFW',
    label: '微信服务'
  },
  {
    value: 'FLKJ',
    label: '峰连科技'
  },
  {
    value: 'SFKJ',
    label: '三峰科技'
  },
  {
    value: 'AJXX',
    label: '昂捷信息'
  },
  {
    value: 'DR',
    label: '数据导入'
  },
  {
    value: 'TS',
    label: '接口推送'
  }
];
